import clsx from 'clsx';
import { useCallback } from 'react';
import { ComponentSize, ComponentType } from '../../../../types';
import { Icons } from '../../Icons';
import { Tooltip, TooltipContent, TooltipTrigger } from '../../Tooltip';
import { BUTTON_SIZE, BUTTON_TYPE } from '../Button.constants';
import '../Button.css';
import { getButtonContentColor } from '../Button.utils';
import ButtonContent from '../ButtonContent';
import { FilledVariant, IconButtonProps } from './IconButton.types';

const IconButton = ({
  active = false,
  icon,
  disabled = false,
  loading,
  size = ComponentSize.SMALL,
  tooltip,
  type = ComponentType.SECONDARY,
  variant = FilledVariant.FILLED,
  onClick,
}: IconButtonProps) => {
  const shouldDisable = disabled || loading || !onClick;

  const getButtonTypeClasses = useCallback(() => {
    if (variant === FilledVariant.FILLED) {
      return BUTTON_TYPE[type];
    } else {
      let classes = 'btn-ghost';
      if (type === ComponentType.DESTRUCTIVE) {
        classes += ' btn-ghost-destructive';
      }
      return classes;
    }
  }, [type, variant]);

  const handleOnClick = onClick
    ? (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        e.stopPropagation();
        e.preventDefault();
        onClick();
      }
    : undefined;

  return (
    <Tooltip>
      <TooltipTrigger>
        <div
          className={clsx(
            'btn btn-square relative',
            BUTTON_SIZE[size],
            getButtonTypeClasses(),
            shouldDisable && 'btn-disabled',
            active && 'btn-active'
          )}
          onClick={handleOnClick}
        >
          <ButtonContent loading={loading}>
            <Icons icon={icon} color={getButtonContentColor(shouldDisable, type, variant)} size={size} />
          </ButtonContent>
        </div>
      </TooltipTrigger>
      <TooltipContent>{tooltip}</TooltipContent>
    </Tooltip>
  );
};

export default IconButton;
