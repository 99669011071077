import { ComponentSize, ComponentType } from '../../../types';
import { ButtonType } from './Button.types';

export const BUTTON_SIZE: Record<ComponentSize, string> = {
  [ComponentSize.X_SMALL]: 'btn-xs',
  [ComponentSize.SMALL]: 'btn-sm',
  [ComponentSize.MEDIUM]: 'btn-md',
  [ComponentSize.LARGE]: 'btn-lg',
};

export const BUTTON_TYPE: Record<ButtonType, string> = {
  [ComponentType.PRIMARY]: 'btn-primary',
  [ComponentType.SECONDARY]: 'btn-secondary',
  [ComponentType.TERTIARY]: 'btn-tertiary',
  [ComponentType.QUATERNARY]: 'btn-ghost',
  [ComponentType.DESTRUCTIVE]: 'btn-destructive',
};
