import { User as Auth0User } from '@auth0/auth0-react';
import { Pagination, PracticeProspect } from './api.types';
import { Candidate } from './candidates.types';

export enum CallProcessingStatus {
  PROCESSING = 'PROCESSING',
  PROCESSED = 'PROCESSED',
  PROCESSING_FAILED = 'PROCESSING_FAILED',
}

export enum CallSpeaker {
  AGENT = 'AGENT',
  CUSTOMER = 'CUSTOMER',
}

export type CallSpeechCategory = {
  id: string;
  callSid: string;
  summary: string;
  speaker: CallSpeaker;
  category: string;
  label: string;
  importanceConfidence: number;
  startTime: number;
  endTime: number;
  createdAt: Date;
  updatedAt: Date;
};

export type Call = {
  callSid: string;
  callStatus: string; // "started" or "ended"
  callType: string; // "inbound" or "outbound"
  incomingPhoneNumber: string;
  orgId: string;
  personaId: string;
  practiceProspect: PracticeProspect;
  receivingPhoneNumber: string;
  startTime: Date;
  audioPath?: string;
  callDuration?: number; // Duration in seconds
  endedReason?: string;
  endTime?: Date;
  transcriptionPath?: string;
  user?: Auth0User;
  userId?: string;
  candidate?: Candidate;
  candidateId?: string;

  // Processing details
  fillerWordsCount?: number;
  longestMonologueDuration?: number;
  longestMonologueStart?: number;
  processingStatus?: CallProcessingStatus;
  summary?: string;
  talkTime?: number;
  wordsCount?: number;

  callSpeechCategories?: CallSpeechCategory[];
};

export enum CallObjective {
  BOOK_A_MEETING = 'BOOK_A_MEETING',
  DISCOVERY = 'DISCOVERY',
  QUALIFY_THE_LEAD = 'QUALIFY_THE_LEAD',
}

export type CallResponseData = {
  call: Call;
};

export type CallsResponseData = {
  calls: Call[];
  pagination: Pagination;
};
