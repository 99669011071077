import React from 'react';
import { ComponentType } from '../../../types';
import TextButton from '../Button/TextButton/TextButton';
import { ButtonGroupProps } from './ButtonGroup.types';

const ButtonGroup = ({ children, loading }: ButtonGroupProps) => {
  return (
    <div className="flex flex-row-reverse gap-2">
      {React.Children.map(children, (child, index) => {
        // Determine the button style type based on its index in the array.
        let type = ComponentType.QUATERNARY;
        if (index === 0) {
          type = ComponentType.SECONDARY;
        } else if (index === 1 && children.length > 2) {
          type = ComponentType.TERTIARY;
        }

        return (
          <TextButton
            {...child.props}
            type={type}
            loading={index === 0 && loading ? true : false}
            disabled={index !== 0 && loading ? true : child.props.disabled}
          />
        );
      })}
    </div>
  );
};

export default ButtonGroup;
