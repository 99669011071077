import { Dispatch, KeyboardEventHandler, SetStateAction, useCallback } from 'react';
import { ComponentSize, TextColor } from '../../../types';
import { TextInput, TextInputType } from '../TextInput';
import { Typography, TypographySize } from '../Typography';

// Validate a country code input.
export function isValidCountryCode(newCode: string): boolean {
  if (newCode === '+') return true;
  if (!Number(newCode)) return false; // Only allow number inputs.
  return newCode.length >= 1 && newCode.length <= 4; // Allow country codes with a maximum of 3 digits.
}

interface PhoneNumberInputProps {
  countryCode: string;
  error: boolean;
  phoneNumber: string;
  onKeyDown: KeyboardEventHandler<HTMLInputElement>;
  setCountryCode: Dispatch<SetStateAction<string>>;
  resetError: () => void;
  setPhoneNumber: Dispatch<SetStateAction<string>>;
  errorMsg?: string;
  isLoading?: boolean;
  size?: ComponentSize.SMALL | ComponentSize.MEDIUM;
}

const PhoneNumberInput = ({
  countryCode,
  error,
  phoneNumber,
  errorMsg,
  isLoading,
  size = ComponentSize.SMALL,
  onKeyDown,
  setCountryCode,
  resetError,
  setPhoneNumber,
}: PhoneNumberInputProps) => {
  const onCountryCodeChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const newCode = e.target.value;
      if (isValidCountryCode(newCode)) {
        setCountryCode(newCode);
        resetError();
      }
    },
    [resetError, setCountryCode]
  );

  const onPhoneNumberChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setPhoneNumber(e.target.value);
      resetError();
    },
    [resetError, setPhoneNumber]
  );

  return (
    <div className="w-full">
      <div className="flex w-full items-start gap-2">
        <div className="w-1/4">
          <TextInput
            value={countryCode}
            size={size}
            onKeyDown={onKeyDown}
            onChange={onCountryCodeChange}
            disabled={isLoading}
            error={error}
          />
        </div>
        <TextInput
          placeholder="Phone number*"
          size={size}
          value={phoneNumber}
          onChange={onPhoneNumberChange}
          type={TextInputType.NUMBER}
          onKeyDown={onKeyDown}
          disabled={isLoading}
          error={error}
        />
      </div>
      {!!errorMsg && (
        <div className="h-4">
          <Typography size={TypographySize.CAPTION} color={TextColor.DESTRUCTIVE}>
            {errorMsg}
          </Typography>
        </div>
      )}
    </div>
  );
};

export default PhoneNumberInput;
