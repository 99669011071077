import { useState } from 'react';
import { ComponentSize } from '../../../types';
import { FilledVariant, IconButton } from '../Button';
import { Divider, DividerType } from '../Divider';
import { Dropdown, DropdownContent, DropdownTrigger } from '../Dropdown';
import { DropdownItem } from '../DropdownItem';
import { Icon } from '../Icons';
import { TextInput } from '../TextInput';
import { Tooltip, TooltipContent, TooltipTrigger } from '../Tooltip';
import { SELECT_END_ICON_SIZE } from './Select.constants';
import { SelectProps } from './Select.types';

const SELECT_DROPDOWN_MAX_HEIGHT = 300;

const Select = ({
  options,
  clearable,
  disabled,
  loading,
  placeholder,
  selected,
  size = ComponentSize.SMALL,
  tooltip,
  onChange,
}: SelectProps) => {
  const [isOpen, setIsOpen] = useState(false);

  const shouldDisable = disabled || loading || !options.length;

  const handleOnChange = (newValue: string) => {
    onChange(newValue);
    setIsOpen(false);
  };

  const renderEndElement = () => (
    <div className="flex items-center">
      {clearable && selected && (
        <Tooltip>
          <TooltipTrigger>
            <IconButton
              icon={Icon.CLOSE}
              variant={FilledVariant.UNFILLED}
              size={SELECT_END_ICON_SIZE[size]}
              onClick={() => onChange(undefined)}
            />
          </TooltipTrigger>
          <TooltipContent>Clear</TooltipContent>
        </Tooltip>
      )}
      <Divider type={DividerType.VERTICAL} />
      <IconButton
        icon={isOpen ? Icon.CHEVRON_UP : Icon.CHEVRON_DOWN}
        size={SELECT_END_ICON_SIZE[size]}
        onClick={() => setIsOpen((prev) => !prev)}
        variant={FilledVariant.UNFILLED}
        disabled={shouldDisable}
      />
    </div>
  );

  return (
    <Dropdown
      open={isOpen}
      onOpenChange={setIsOpen}
      disabled={shouldDisable}
      fullWidth
      maxHeight={SELECT_DROPDOWN_MAX_HEIGHT}
    >
      <DropdownTrigger>
        <Tooltip>
          <TooltipTrigger fullWidth>
            <TextInput
              placeholder={placeholder}
              value={loading ? 'Loading...' : selected?.label || ''}
              size={size}
              endElement={renderEndElement()}
              disabled={shouldDisable}
            />
          </TooltipTrigger>
          <TooltipContent>{tooltip}</TooltipContent>
        </Tooltip>
      </DropdownTrigger>
      {!!options.length && (
        <DropdownContent>
          {options.map((option, index) => (
            <div key={option.value}>
              {option.divider && index > 0 && <Divider />}
              <DropdownItem
                label={option.label}
                active={selected?.value === option.value}
                onClick={() => handleOnChange(option.value)}
                destructive={option.destructive}
              />
            </div>
          ))}
        </DropdownContent>
      )}
    </Dropdown>
  );
};

export default Select;
