/**
 * Enum for component alignment.
 */
export enum Alignment {
  CENTER = 'center',
  LEFT = 'left',
}

/**
 * Enum for text and icon colors.
 */
export enum TextColor {
  DESTRUCTIVE = 'destructive',
  LINK = 'link',
  PRIMARY = 'primary',
  SECONDARY = 'secondary',
  SUCCESS = 'success',
  TERTIARY = 'tertiary',
  WHITE = 'white',
}

/**
 * Enum for component sizes.
 * Provides a set of predefined sizes for UI components.
 */
export enum ComponentSize {
  X_SMALL = 'x-small',
  SMALL = 'small',
  MEDIUM = 'medium',
  LARGE = 'large',
}

/**
 * Enum for component types.
 * Defines different types of UI components for styling and behavior.
 */
export enum ComponentType {
  PRIMARY = 'primary',
  SECONDARY = 'secondary',
  TERTIARY = 'tertiary',
  QUATERNARY = 'quaternary',
  DESTRUCTIVE = 'destructive',
}

/**
 * Type for mouse position coordinates.
 * Represents the x and y coordinates of the mouse pointer.
 */
export type MousePosition = {
  x: number;
  y: number;
};
