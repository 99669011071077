import { Dispatch, KeyboardEvent, SetStateAction, useState } from 'react';
import { useInitiateCallMutation } from '../../../../../../services/api.services';
import { FilledVariant, Icon, IconButton, PhoneNumberInput } from '../../../../../shared';
import { PhoneVerificationModal } from '../../../../PhoneVerificationModal';

interface AddPhoneNumberProps {
  error: string;
  user: { id: string; phoneNumbers: string[] };
  onHide: () => void;
  setError: Dispatch<SetStateAction<string>>;
  onAddPhoneNumber: () => void;
}

const AddPhoneNumber = ({ user, onHide, setError, error, onAddPhoneNumber }: AddPhoneNumberProps) => {
  // State
  // Default to US country code.
  const [countryCode, setCountryCode] = useState('+1');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [verificationCode, setVerificationCode] = useState('');
  const [isVerificationModalOpen, setIsVerificationModalOpen] = useState(false);

  // Mutations
  const [initiateCall, { isLoading }] = useInitiateCallMutation();

  // Handles initiating call to verify user phone number.
  const onSubmitPhoneNumber = async () => {
    if (!phoneNumber.length) {
      setError('Field cannot be empty');
      return;
    }

    if (countryCode === '+') {
      setError('Country code is required');
      return;
    }

    //Regex check for invalid phone numbers
    const newPhoneNumber = countryCode + phoneNumber;
    if (!/^\+\d{10,15}$/.test(newPhoneNumber)) {
      setError('Invalid phone number');
      return;
    }

    // Check if the new number being saved already exists.
    const isDuplicated = user.phoneNumbers.includes(newPhoneNumber);

    // Exit if the number already exists and update the error state.
    if (isDuplicated) {
      setError('Duplicate phone number');
      return;
    }

    // Initiate a phone verification call.
    const call = await initiateCall({ to: newPhoneNumber });

    if (call.error) {
      setError('Failed to initiate a phone verification call, please check your phone number');
      return;
    }

    // If the call is successful, show the phone verification modal.
    if (call.data) {
      setVerificationCode(call.data.verificationCode);
      setIsVerificationModalOpen(true);
    }
  };

  const handleKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      onSubmitPhoneNumber();
    }
  };

  return (
    <div className="flex items-start gap-2">
      <PhoneNumberInput
        phoneNumber={phoneNumber}
        setPhoneNumber={setPhoneNumber}
        countryCode={countryCode}
        setCountryCode={setCountryCode}
        onKeyDown={handleKeyDown}
        isLoading={isLoading}
        error={!!error.length}
        errorMsg={error}
        resetError={() => {
          if (error.length) {
            setError('');
          }
        }}
      />
      <IconButton
        icon={Icon.CLOSE}
        onClick={() => {
          onHide();
          // Reset the error state on hiding the input field.
          if (error.length) {
            setError('');
          }
        }}
        variant={FilledVariant.UNFILLED}
        tooltip="Cancel"
      />
      <PhoneVerificationModal
        isOpen={isVerificationModalOpen}
        setError={setError}
        onHide={() => setIsVerificationModalOpen(false)}
        phoneVerificationData={{ userId: user.id, phoneNumber: countryCode + phoneNumber, verificationCode }}
        onVerify={onAddPhoneNumber}
      />
    </div>
  );
};

export default AddPhoneNumber;
