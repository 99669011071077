import { useCallback, useEffect, useState } from 'react';
import { useAppSelector, useHandleApiResponse, useToast } from '../../../../../../hooks';
import { useDeleteUserPhoneNumberMutation } from '../../../../../../services';
import { ComponentType, EditUserPhoneNumberPayload, TextColor } from '../../../../../../types';
import {
  AlertType,
  ButtonGroup,
  ButtonGroupItem,
  Dialog,
  DialogType,
  FilledVariant,
  Icon,
  IconButton,
  TextButton,
  Typography,
  TypographyWeight,
} from '../../../../../shared';
import AddPhoneNumber from './AddPhoneNumber';

const ERROR_MSG = 'Failed to delete phone number';
const SUCCESS_MSG = 'Phone number deleted successfully';

interface UserPhoneNumbersProps {
  // The current user's data.
  user: { id: string; phoneNumbers: string[] };
}

const UserPhoneNumbers = ({ user }: UserPhoneNumbersProps) => {
  // State
  const [error, setError] = useState('');
  const [isAddingPhoneNumber, setIsAddingPhoneNumber] = useState(false);
  const [phoneNumberToDelete, setPhoneNumberToDelete] = useState<string>('');

  // Redux
  const {
    settings: { isOpen },
  } = useAppSelector((state) => state.modal);

  // Custom hooks
  const { showToast } = useToast();
  const handleApiResponse = useHandleApiResponse();

  // Mutations
  const [deleteUserPhoneNumber, { isLoading: isDeletingPhoneNumberLoading }] = useDeleteUserPhoneNumberMutation();

  // Handles deleting a phone number from the user profile.
  const handleDeletePhoneNumber = useCallback(
    async (payload: EditUserPhoneNumberPayload) => {
      try {
        const response = await deleteUserPhoneNumber(payload);

        // Handles successful deletion by resetting the error state
        // and clearing phoneNumberToDelete which closes the confirm modal.
        const onSuccess = () => {
          if (error.length) setError('');
          setPhoneNumberToDelete('');
        };

        handleApiResponse({ response, errorMsg: ERROR_MSG, successMsg: SUCCESS_MSG, onSuccess });
      } catch (error) {
        console.error(`${ERROR_MSG}: `, error);
        showToast({ message: ERROR_MSG, type: AlertType.ERROR });
      }
    },
    [handleApiResponse, setError, setPhoneNumberToDelete, showToast]
  );

  const addPhoneNumber = () => {
    setError('');
    setIsAddingPhoneNumber(false);
  };

  useEffect(() => {
    if (isOpen) {
      setIsAddingPhoneNumber(false);
      setError('');
    }
  }, [isOpen]);

  const onDelete = () => {
    // Create an updated user object.
    const payload = { ...user, phoneNumber: phoneNumberToDelete };
    // Run edit.
    handleDeletePhoneNumber(payload);
  };

  return (
    <div className="flex w-full flex-col gap-2">
      <Typography color={TextColor.SECONDARY}>Phone number(s)</Typography>
      <div className="flex flex-col gap-2">
        {user.phoneNumbers.map((phoneNumber) => (
          <div key={phoneNumber} className="flex h-8 items-center justify-between">
            <Typography>{phoneNumber}</Typography>
            <IconButton
              icon={Icon.TRASH}
              variant={FilledVariant.UNFILLED}
              onClick={() => setPhoneNumberToDelete(phoneNumber)}
              disabled={user.phoneNumbers.length === 1}
              type={ComponentType.DESTRUCTIVE}
              tooltip={
                user.phoneNumbers.length === 1 ? 'At least one phone number is required.' : 'Delete phone number.'
              }
            />
          </div>
        ))}
        {!isAddingPhoneNumber && (
          <TextButton
            text="Add phone number"
            startIcon={Icon.PLUS}
            fullWidth
            onClick={() => setIsAddingPhoneNumber(true)}
            disabled={user.phoneNumbers.length >= 3}
            tooltip={user.phoneNumbers.length >= 3 ? 'Maximum of 3 phone numbers allowed.' : ''}
          />
        )}
        {isAddingPhoneNumber && (
          <AddPhoneNumber
            user={user}
            setError={setError}
            error={error}
            onHide={() => setIsAddingPhoneNumber(false)}
            onAddPhoneNumber={addPhoneNumber}
          />
        )}
      </div>
      <Dialog
        isOpen={!!phoneNumberToDelete.length}
        onClose={() => setPhoneNumberToDelete('')}
        title="Confirm delete phone number"
        type={DialogType.CONFIRM}
      >
        <div className="flex flex-col gap-2">
          <Typography>
            Are you sure you want to delete the phone number{' '}
            <Typography weight={TypographyWeight.MEDIUM}>{phoneNumberToDelete}</Typography>?
          </Typography>
          <ButtonGroup loading={isDeletingPhoneNumberLoading}>
            <ButtonGroupItem key="confirm" text="Delete" onClick={onDelete} />
            <ButtonGroupItem key="cancel" text="Cancel" onClick={() => setPhoneNumberToDelete('')} />
          </ButtonGroup>
        </div>
      </Dialog>
    </div>
  );
};

export default UserPhoneNumbers;
