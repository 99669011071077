/** Enum for the type of divider, either horizontal or vertical. */
export enum DividerType {
  HORIZONTAL = 'horizontal',
  VERTICAL = 'vertical',
}

/** Defines properties for the Divider component. */
export interface DividerProps {
  // Optional text to display within the divider.
  text?: string;
  // The type of the divider, either horizontal or vertical.
  type?: DividerType;
}
