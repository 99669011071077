import { useMemo } from 'react';
import { useGetFiltersFromParams, useUpdateFilterParams } from '../../hooks';
import {
  CallObjective,
  PracticeFilterKeys,
  PracticeFilters as PracticeFiltersType,
  PracticeProspect,
  ProspectType,
} from '../../types';
import {
  getProspectOptions,
  getSelectedOption,
  parseOptionalSelectOption,
  parseRequiredSelectOption,
} from '../../utils';
import { SidebarDateFilter, SidebarSearchFilter, SidebarSelectFilter } from './SidebarFilterFields';

/**
 * Creates prospect account options from prospect data.
 */
export const getProspectAccountOptions = (prospectSelectData: PracticeProspect[]) => {
  const accountsSet: Set<string> = new Set();
  prospectSelectData.forEach((prospect) => accountsSet.add(prospect.accountName));
  return Array.from(accountsSet).map((account) => parseRequiredSelectOption(account));
};

interface PracticeFiltersProps {
  prospectSelectData: PracticeProspect[];
}

const PracticeFilters = ({ prospectSelectData }: PracticeFiltersProps) => {
  // Retrieve filters from URL parameters.
  const filters: PracticeFiltersType = useGetFiltersFromParams();

  // Hook to update filter parameters in the URL.
  const updateFilterParams = useUpdateFilterParams();

  // Memoize prospect options to avoid unnecessary recalculations.
  const prospectOptions = useMemo(() => getProspectOptions(prospectSelectData), [prospectSelectData]);

  // Memoize prospect account options to avoid unnecessary recalculations
  const prospectAccountOptions = useMemo(() => getProspectAccountOptions(prospectSelectData), [prospectSelectData]);

  // Find the selected prospect option based on the current filters.
  const selectedProspect = useMemo(
    () => getSelectedOption(prospectOptions, filters.prospect),
    [prospectOptions, filters.prospect]
  );

  return (
    <>
      {/* Search filter for prospects */}
      <SidebarSearchFilter
        onChange={(e) => updateFilterParams(PracticeFilterKeys.SEARCH, e.target.value)}
        placeholder="Search prospects"
        value={filters.search}
      />
      {/* Prospect filter */}
      <SidebarSelectFilter
        options={prospectOptions}
        title="Prospect"
        placeholder="Select prospect"
        selected={selectedProspect}
        onChange={(newValue?: string) => updateFilterParams(PracticeFilterKeys.PROSPECT, newValue)}
      />
      {/* Prospect Account filter */}
      <SidebarSelectFilter
        options={prospectAccountOptions}
        title="Prospect Account"
        placeholder="Select prospect account"
        selected={parseOptionalSelectOption(filters.prospectAccount)}
        onChange={(newValue?: string) => updateFilterParams(PracticeFilterKeys.PROSPECT_ACCOUNT, newValue)}
      />
      {/* Prospect Type filter */}
      <SidebarSelectFilter
        options={Object.values(ProspectType).map(parseRequiredSelectOption)}
        title="Prospect Type"
        placeholder="Select prospect type"
        selected={parseOptionalSelectOption(filters.prospectType)}
        onChange={(newValue?: string) => updateFilterParams(PracticeFilterKeys.PROSPECT_TYPE, newValue)}
      />
      {/* Call Objective filter */}
      <SidebarSelectFilter
        options={Object.values(CallObjective).map(parseRequiredSelectOption)}
        title="Call Objective"
        placeholder="Select call objective"
        selected={parseOptionalSelectOption(filters.callObjective)}
        onChange={(newValue?: string) => updateFilterParams(PracticeFilterKeys.CALL_OBJECTIVE, newValue)}
      />
      {/* Date Created filter */}
      <SidebarDateFilter
        title="Date Created"
        onChange={(newValue?: string) => updateFilterParams(PracticeFilterKeys.DATE_CREATED, newValue)}
        selected={parseOptionalSelectOption(filters.dateCreated)}
      />
      {/* Last Updated filter */}
      <SidebarDateFilter
        title="Last Updated"
        onChange={(newValue?: string) => updateFilterParams(PracticeFilterKeys.LAST_UPDATED, newValue)}
        selected={parseOptionalSelectOption(filters.lastUpdated)}
      />
    </>
  );
};

export default PracticeFilters;
