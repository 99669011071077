/**
 * Capitalizes the first letter of a given word.
 */
export function capitalizeFirstLetter(word: string): string {
  if (word.length === 0) return word;
  return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
}

/** Converts a camel-case string to a label format with all words capitalized. */
export function camelCaseToLabel(str?: string) {
  if (!str) return '';

  // Exit if string is empty.
  if (!str.length) return str;

  // Convert the camel case string into space-separated words.
  let label = str.replace(/([A-Z])/g, ' $1').trim();

  // Capitalize the first letter of each word.
  label = label
    .split(' ')
    .map((word) => capitalizeFirstLetter(word))
    .join(' ');

  // Replace sequences of spaces with a single space.
  return label.replace(/\s+/g, ' ');
}

/** Converts a kebab-case string to a label format with the first word capitalized. */
export function kebabCaseToLabel(str?: string) {
  if (!str) return '';

  // Exit if string is empty.
  if (!str.length) return str;

  // Split the string by the hyphen.
  const words = str.split('-');

  // Capitalize only the first word.
  words[0] = capitalizeFirstLetter(words[0]);

  // Join the segments back with spaces.
  return words.join(' ');
}

/**
 * Converts a snake_case string to a label with the first word capitalized.
 */
export function snakeCaseToLabel(str?: string) {
  if (!str) return '';

  // Exit if the string is empty.
  if (!str.length) return str;

  // Replace underscores with spaces and convert the entire string to lowercase.
  const formattedString = str.replace(/_/g, ' ').toLowerCase();

  // Capitalize the first character of the string and concatenate it with the rest of the string.
  return capitalizeFirstLetter(formattedString);
}

/**
 * Formats a duration in seconds into a string of format MM:SS.
 */
export function formatDuration(seconds: number) {
  const minutes = Math.floor(seconds / 60);
  const secs = Math.floor(seconds % 60);
  return `${minutes}:${secs < 10 ? '0' : ''}${secs}`;
}

/**
 * Formats a duration in seconds into a string of format H h M m S s.
 */
export function formatDurationVerbose(seconds: number): string {
  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);
  const secs = Math.floor(seconds % 60);

  const formattedHours = hours > 0 ? `${hours}h ` : '';
  const formattedMinutes = minutes > 0 ? `${minutes}m ` : '';
  const formattedSeconds = `${secs}s`;

  return `${formattedHours}${formattedMinutes}${formattedSeconds}`.trim();
}

/**
 * Formats a phone number string to the standard format with the country code.
 */
export function formatPhoneNumber(phoneNumber: string) {
  // Remove all non-numeric characters except the plus sign
  const cleaned = phoneNumber.replace(/[^\d+]/g, '');

  // Regex to extract country code and digits
  const match = cleaned.match(/(\+\d+)?(\d{3})(\d{3})(\d{4})/);

  if (match) {
    const countryCode = match[1] ? match[1] : '+1'; // Default to +1 if no country code
    const areaCode = match[2];
    const middle = match[3];
    const last = match[4];
    return `${countryCode} (${areaCode}) ${middle} ${last}`;
  } else {
    // Return the original string if it doesn't match the expected pattern
    return phoneNumber;
  }
}

/** Utility function to convert size values to string with 'px' if needed. */
export const convertToPx = (value?: number | string) => (typeof value === 'number' ? `${value}px` : value);
