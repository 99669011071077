import clsx from 'clsx';
import { TEXT_COLOR_VALUE } from '../../../constants';
import { Alignment, TextColor } from '../../../types';
import { TYPOGRAPHY_ALIGNMENT, TYPOGRAPHY_SIZE, TYPOGRAPHY_WEIGHT } from './Typography.constants';
import { TypographyProps, TypographySize, TypographyWeight } from './Typography.types';

const Typography = ({
  children,
  alignment = Alignment.LEFT,
  className,
  color = TextColor.PRIMARY,
  size = TypographySize.PARAGRAPH,
  underline,
  weight = TypographyWeight.NORMAL,
}: TypographyProps) => {
  return (
    <span
      className={clsx(
        TEXT_COLOR_VALUE[color],
        TYPOGRAPHY_ALIGNMENT[alignment],
        TYPOGRAPHY_SIZE[size],
        TYPOGRAPHY_WEIGHT[weight],
        underline && 'underline',
        className
      )}
    >
      {children}
    </span>
  );
};

export default Typography;
