import clsx from 'clsx';
import { ComponentSize } from '../../../types';
import { CHECKBOX_SIZE } from './Checkbox.constants';

interface CheckboxProps {
  checked: boolean;
  setChecked: (checked: boolean) => void;
  size?: ComponentSize;
}

const Checkbox = ({ checked, setChecked, size = ComponentSize.SMALL }: CheckboxProps) => {
  return (
    <input
      type="checkbox"
      className={clsx('checkbox rounded-sm !outline-none', CHECKBOX_SIZE[size])}
      checked={checked}
      onChange={(e) => setChecked(e.target.checked)}
    />
  );
};

export default Checkbox;
