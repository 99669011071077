import { TextInput, TextInputProps, Typography } from '../../shared';

const SidebarSearchFilter = ({ ...inputProps }: TextInputProps) => {
  return (
    <div className="flex flex-col gap-2">
      <Typography>Search</Typography>
      <TextInput {...inputProps} />
    </div>
  );
};

export default SidebarSearchFilter;
