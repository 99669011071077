import { useAppDispatch, useAppSelector } from '../../../hooks';
import { useFeatureFlag } from '../../../hooks/useFeatureFlag';
import { SettingsModalTabs, closeSettingsModal, setSettingsActiveTab } from '../../../redux/reducers';
import { Permissions } from '../../../types';
import { LD_FeatureFlags } from '../../../types/launch-darkly.types';
import { conditionalArray } from '../../../utils';
import { Dialog, DialogType, Icon, Tabs } from '../../shared';
import { OrganizationTab, ProfileTab } from './tabs';
import HiringTab from './tabs/HiringTab';

const SETTINGS_WITH_INVITE_HEIGHT = 496;
const SETTINGS_WITHOUT_INVITE_HEIGHT = 432;

const SettingsModal = () => {
  const dispatch = useAppDispatch();
  const { permissions = [] } = useAppSelector((state) => state.auth.user) || {};
  const { isHiringModuleEnabled } = useAppSelector((state) => state.auth.organization?.metadata) || {};
  const { isOpen, activeTab } = useAppSelector((state) => state.modal.settings);

  const hiringFF = useFeatureFlag(LD_FeatureFlags.HIRING_MODULE);

  // Check if the curr user is allowed to invite other users.
  const userCanInvite = permissions.includes(Permissions.INVITE_USER);
  const contentHeight = userCanInvite ? SETTINGS_WITH_INVITE_HEIGHT : SETTINGS_WITHOUT_INVITE_HEIGHT;

  const tabs = [
    {
      id: SettingsModalTabs.PROFILE,
      title: 'Profile',
      content: <ProfileTab />,
    },
    {
      id: SettingsModalTabs.ORGANIZATION,
      title: 'Organization',
      content: <OrganizationTab />,
    },
    ...conditionalArray(permissions.includes(Permissions.VIEW_CANDIDATE), {
      content: <HiringTab />,
      disabled: !hiringFF || !isHiringModuleEnabled,
      id: SettingsModalTabs.HIRING,
      title: 'Hiring',
      icon: !hiringFF || !isHiringModuleEnabled ? Icon.LOCK : undefined,
    }),
  ];

  return (
    <Dialog
      title="Settings"
      isOpen={isOpen}
      onClose={() => dispatch(closeSettingsModal())}
      type={DialogType.SETTINGS}
      padding="32px 32px 0"
    >
      <div style={{ height: contentHeight }}>
        <Tabs
          tabs={tabs}
          activeTab={activeTab}
          setActiveTab={(newActiveTab) => dispatch(setSettingsActiveTab(newActiveTab as SettingsModalTabs))}
        />
      </div>
    </Dialog>
  );
};

export default SettingsModal;
