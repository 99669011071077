export enum LD_ContextKinds {
  USER = 'user',
}

export enum LD_ContextRoles {
  SUPER_ADMIN = 'super-admin',
  SYSTEM_USER = 'system-user',
}

export enum LD_FeatureFlags {
  DETAILED_MEDIA_PLAYER_VIEW = 'detailed-media-player-view',
  RELEASE_DETAILED_MEDIA_PLAYER_COMMENTS = 'release-detailed-media-player-comments',
  RELEASE_INTERCOM_SUPPORT = 'release-intercom-support',
  HIRING_MODULE = 'hiring-module',
}
