import AddCandidate from './AddCandidate';
import CandidateTable from './CandidateTable/CandidateTable';

const HiringTab = () => {
  return (
    <div className="flex flex-col gap-4">
      <AddCandidate />
      <CandidateTable />
    </div>
  );
};

export default HiringTab;
