import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { AppRoutes } from '../constants';
import { CallHistoryFilterKeys, CallHistoryFilters, OrgRoles, PracticeFilters } from '../types';
import useAppSelector from './useAppSelector';

/**
 * Extracts all key-value pairs from the given URLSearchParams object.
 */
const getAllParams = (params: URLSearchParams): Record<string, string> => {
  const entries: Record<string, string> = {};
  params.forEach((value, key) => {
    entries[key] = value;
  });
  return entries;
};
const useGetFiltersFromParams = () => {
  const location = useLocation();

  const params = useMemo(() => new URLSearchParams(location.search), [location.search]);
  const isCallHistoryPage = useMemo(() => location.pathname.includes(AppRoutes.CALL_HISTORY), [location.pathname]);

  const user = useAppSelector((state) => state.auth.user);
  const isSalesRep = useMemo(() => user?.role === OrgRoles.SALES_REP, [user?.role]);

  const filters = useMemo(() => {
    const allParams = getAllParams(params);

    if (isCallHistoryPage && isSalesRep) {
      delete allParams[CallHistoryFilterKeys.CALLER];
    }

    return allParams as PracticeFilters | CallHistoryFilters;
  }, [params, isCallHistoryPage, isSalesRep]);

  return filters;
};

export default useGetFiltersFromParams;
