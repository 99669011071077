import dayjs from 'dayjs';
import { DateOptions, DateUnit } from '../types';

/**
 * Checks if the given date is within the last 2 days from the current date.
 */
export const isWithinLastTwoDays = (date: Date): boolean => {
  const now = new Date();
  const twoDaysAgo = new Date(now.getFullYear(), now.getMonth(), now.getDate() - 2);
  return date > twoDaysAgo;
};

/**
 * Helper function to get the start and end dates for a given date and unit.
 */
const getStartAndEnd = (date: dayjs.Dayjs, unit: DateUnit) => ({
  start: date.startOf(unit).toDate(),
  end: date.endOf(unit).toDate(),
});

/**
 * Gets the start and end dates based on the provided DateOptions value.
 */
export const getStartAndEndDatesFromDateOption = (value?: DateOptions) => {
  if (!value) return;

  const today = dayjs();

  switch (value) {
    case DateOptions.TODAY:
      return getStartAndEnd(today, DateUnit.DAY);
    case DateOptions.YESTERDAY:
      return getStartAndEnd(today.subtract(1, DateUnit.DAY), DateUnit.DAY);
    case DateOptions.THIS_WEEK:
      return getStartAndEnd(today, DateUnit.WEEK);
    case DateOptions.LAST_WEEK:
      return getStartAndEnd(today.subtract(1, DateUnit.WEEK), DateUnit.WEEK);
    case DateOptions.THIS_MONTH:
      return getStartAndEnd(today, DateUnit.MONTH);
    case DateOptions.LAST_MONTH:
      return getStartAndEnd(today.subtract(1, DateUnit.MONTH), DateUnit.MONTH);
    default:
      throw new Error('Invalid DateOption');
  }
};
