import { Icon } from '../Icons';
import { AlertType } from './Alert.types';

export const TYPE_TO_ALERT_TYPE: Record<AlertType, string> = {
  error: 'bg-error-content',
  info: 'alert-info',
  success: 'bg-success-content',
  warning: 'alert-warning',
};

export const TYPE_TO_ICON: Record<AlertType, Icon> = {
  error: Icon.CIRCLE_X,
  info: Icon.INFO,
  success: Icon.CIRCLE_CHECK,
  warning: Icon.TRIANGLE_ALERT,
};
