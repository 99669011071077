import { ComponentSize } from '../../../types';
import { BadgeColor, BadgeSize, BadgeType } from './Badge.types';

export const BADGE_COLOR: Record<BadgeColor, string> = {
  [BadgeColor.DEFAULT]: 'badge-neutral',
  [BadgeColor.ERROR]: 'badge-error',
  [BadgeColor.SUCCESS]: 'badge-success',
};

export const BADGE_SIZE: Record<BadgeSize, string> = {
  [ComponentSize.SMALL]: 'badge-sm',
  [ComponentSize.MEDIUM]: 'badge-md',
  [ComponentSize.LARGE]: 'badge-lg',
};

export const BADGE_TYPE: Record<BadgeType, string> = {
  [BadgeType.DEFAULT]: '',
  [BadgeType.OUTLINE]: 'badge-outline',
};
