import { User as Auth0User } from '@auth0/auth0-react';
import { SerializedError } from '@reduxjs/toolkit';
import { FetchBaseQueryError } from '@reduxjs/toolkit/query';

export enum ApiTagTypes {
  CANDIDATES = 'Candidates',
  COMMENTS = 'Comments',
  INVITES = 'Invites',
  PHONE_NUMBERS = 'PhoneNumbers',
  USERS = 'Users',
}

export type PostErrorResponse = {
  status: number;
  message: string;
  errors?: string[];
};

export type PostSuccessResponse = {
  message: string;
};

export type ApiResponse = {
  data?: PostSuccessResponse;
  error?: FetchBaseQueryError | SerializedError;
};

export type Invite = {
  id: string;
  client_id: string;
  inviter: { name: string };
  invitee: { email: string };
  invitation_url: string;
  roles: string[];
};

export interface InvitesResponse {
  data: {
    invitations: Invite[];
  };
}

/** Defines the structure of a response from the server containing a list of users. */
export interface OrgUsersResponse {
  data: {
    users: Auth0User[];
  };
}

export enum ProspectType {
  COLD = 'COLD',
  WARM = 'WARM',
}

/** Represents a practice prospect as stored in the database. */
export type PracticeProspect = {
  associatedPhoneNumber: string;
  createdAt: Date;
  createdBy: string;
  firstMessage: string;
  firstName: string;
  jobTitle: string;
  lastName: string;
  objective: string;
  accountName: string;
  personaId: string;
  personaPrompt: string;
  personaVoiceProvider: string;
  personaVoiceId: string;
  type: ProspectType;
  lastModified: Date;
  lastModifiedBy: string;
  notes?: string;
};

export type Pagination = {
  currentPage: number;
  totalPages: number;
  totalItems: number;
  pageSize: number;
};

export type PracticeProspectsResponseData = {
  prospects: PracticeProspect[];
  pagination: Pagination;
};

/** Defines the structure of a response from the server containing a list of phone numbers. */
export interface PhoneNumbersResponse {
  data: {
    phoneNumbers: string[];
  };
}

export type UsersSelectResponse = {
  data: {
    phoneNumbers?: string[];
    users: Auth0User[];
  };
};

export type InitiateCallResponseData = {
  verificationCode: string;
};

export type InitiateCallResponse = {
  data: InitiateCallResponseData;
  success: boolean;
  message: string;
};
