import { Avatar, Typography, TypographySize, TypographyWeight } from '../../components';
import { CallSpeaker, TextColor } from '../../types';
import { formatDuration } from '../../utils';

type WordBase = {
  word: string;
  start: number;
  end: number;
  confidence: number;
  punctuated_word?: string;
  speaker?: number;
  speaker_confidence?: number;
};

export type Utterance = {
  start: number;
  end: number;
  transcript: string;
  speaker: CallSpeaker;
  channel: number;
  id: string;
  words: WordBase[];
};

type CallTranscriptRowProps = {
  speakerName: string;
  startTime: number;
  transcript: string;
  seekToAudio: () => void;
  speakerPicture?: string;
};

const CallTranscriptRow = ({
  speakerName,
  startTime,
  transcript,
  seekToAudio,
  speakerPicture,
}: CallTranscriptRowProps) => {
  return (
    <div className="flex items-start gap-2">
      <Typography color={TextColor.SECONDARY} className="mt-2">
        {formatDuration(startTime)}
      </Typography>
      <div className="flex w-full cursor-pointer gap-4 rounded-lg p-2 hover:bg-success" onClick={seekToAudio}>
        <div className="h-6 flex-shrink-0">
          <Avatar imgSrc={speakerPicture} label={speakerName} />
        </div>
        <span>
          <Typography size={TypographySize.H5} weight={TypographyWeight.SEMI_BOLD}>
            {speakerName}:{' '}
          </Typography>
          <Typography size={TypographySize.H5} weight={TypographyWeight.NORMAL}>
            {transcript}
          </Typography>
        </span>
      </div>
    </div>
  );
};

export default CallTranscriptRow;
