import { Typography, TypographySize, TypographyWeight } from '../../components';
import { TextColor } from '../../types';

interface CallStatsCardProps {
  title: string;
  value: string | number;
  subtitle: string;
}

const CallStatsCard = ({ title, value, subtitle }: CallStatsCardProps) => {
  return (
    <div className="flex flex-col gap-1 rounded-lg border border-base-200 p-4">
      <Typography>{title}</Typography>
      <Typography size={TypographySize.H3} weight={TypographyWeight.SEMI_BOLD}>
        {value}
      </Typography>
      <Typography size={TypographySize.CAPTION} color={TextColor.SECONDARY}>
        {subtitle}
      </Typography>
    </div>
  );
};

export default CallStatsCard;
