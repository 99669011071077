import { AppUser, Call } from '../types';

/**
 * Retrieves the caller information for a given call and current user.
 */
export const getCallerInfo = (call: Call, currUser: AppUser) => {
  // If the call has no associated userId or candidateId, meaning the caller is a non-FullyRamped user,
  // return the incoming phone number as the caller's unique ID and name.
  if (!call.userId && !call.candidateId) {
    return { id: call.incomingPhoneNumber, name: call.incomingPhoneNumber };
  }

  // If the caller's userId matches the current user's ID,
  // return the current user's details.
  if (call.userId === currUser.id) {
    return { id: currUser.id, name: currUser.name || '', picture: currUser.picture };
  }

  // If the call has an associated user object, return the user's details.
  if (call.user) {
    return { id: call.user.id, name: call.user.name || '', picture: call.user.picture };
  }

  // If the call has an associated candidate object, return the candidate's details.
  if (call.candidate) {
    return {
      id: call.candidate.candidateId,
      name: `${call.candidate.firstName} ${call.candidate.lastName}`.trim(),
    };
  }

  // If none of the above conditions are met, return the incoming phone number as the caller's unique ID and name.
  return { id: call.incomingPhoneNumber, name: call.incomingPhoneNumber };
};
