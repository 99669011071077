import * as RadixTabs from '@radix-ui/react-tabs';
import clsx from 'clsx';
import { Alignment, TextColor } from '../../../types';
import { Icons } from '../Icons';
import { Typography } from '../Typography';
import './Tabs.css';
import { TabsProps } from './Tabs.types';

const Tabs = ({ activeTab, className, tabs, setActiveTab, alignment }: TabsProps) => {
  return (
    <RadixTabs.Root
      onValueChange={setActiveTab}
      value={activeTab}
      className={clsx('flex h-full flex-col gap-8', className)}
    >
      <RadixTabs.List className={clsx('tabs-boxed tabs', alignment === Alignment.LEFT && 'justify-start')}>
        {tabs.map(({ id, title, disabled, icon }) => {
          const isActive = activeTab === id;
          const textColor = isActive ? TextColor.PRIMARY : TextColor.SECONDARY;
          return (
            <RadixTabs.Trigger
              key={id}
              value={id}
              className={clsx('tab', isActive && 'tab-active', disabled && 'tab-disabled')}
            >
              {icon && <Icons icon={icon} color={textColor} />}
              <Typography color={textColor}>{title}</Typography>
            </RadixTabs.Trigger>
          );
        })}
      </RadixTabs.List>
      {tabs.map(({ content, id }) => (
        <RadixTabs.Content key={id} value={id} className="h-full">
          {content}
        </RadixTabs.Content>
      ))}
    </RadixTabs.Root>
  );
};

export default Tabs;
