import { OrgRoles } from '../types';

export const GOOGLE_USER_ID_PREFIX = 'google-oauth2';

/**
 * The full claim identifier for app metadata within the JWT.
 * This claim contains user-specific data like disabled and onboarded.
 */
export const JWT_NAMESPACE_APP_METADATA_CLAIM = 'https://api.fullyramped.com/api/app_metadata';

/**
 * The full claim identifier for organization information within the JWT.
 * This claim contains organization-specific data like display name and organization ID.
 */
export const JWT_NAMESPACE_ORG_CLAIM = 'https://api.fullyramped.com/api/organization';

/**
 * The full claim identifier for role information within the JWT.
 * This claim is used to store the roles associated with the user,
 * which dictate access levels and permissions within the application.
 */
export const JWT_NAMESPACE_ROLES_CLAIM = 'https://api.fullyramped.com/api/roles';

/**
 * A mapping of organization roles with their hierarchy numbers.
 * The higher the number, the higher the role.
 */
export const ROLE_HIERARCHY: Record<OrgRoles | 'noRole', number> = {
  [OrgRoles.SUPER_ADMIN]: 5,
  [OrgRoles.ADMIN]: 4,
  [OrgRoles.MANAGER]: 3,
  [OrgRoles.SALES_REP]: 2,
  noRole: 1,
};

/**
 * A mapping of organization roles with their labels.
 */
export const ROLE_LABEL: Record<OrgRoles, string> = {
  [OrgRoles.SUPER_ADMIN]: 'Super Admin',
  [OrgRoles.ADMIN]: 'Admin',
  [OrgRoles.MANAGER]: 'Manager',
  [OrgRoles.SALES_REP]: 'Representative',
};
