import { useMemo } from 'react';
import { useAppSelector, useGetFiltersFromParams, useUpdateFilterParams } from '../../hooks';
import { useGetUsersForSelectQuery } from '../../services';
import {
  AppUser,
  CallHistoryFilterKeys,
  CallHistoryFilters as CallHistoryFiltersType,
  CallObjective,
  OrgRoles,
  PracticeProspect,
  ProspectType,
} from '../../types';
import {
  getProspectOptions,
  getSelectedOption,
  parseOptionalSelectOption,
  parseRequiredSelectOption,
} from '../../utils';
import { SidebarDateFilter, SidebarSelectFilter } from './SidebarFilterFields';

/**
 * Creates caller options from user data.
 */
const getCallerOptions = (userData?: { users: AppUser[]; phoneNumbers?: string[] }) => {
  const mappedUsers = userData?.users.map(({ id, name = '' }) => ({ value: id, label: name })) || [];
  const mappedPhoneNumbers = (userData?.phoneNumbers || []).map((phoneNumber) => ({
    value: phoneNumber,
    label: phoneNumber,
  }));
  return mappedUsers.concat(mappedPhoneNumbers);
};

interface CallHistoryFiltersProps {
  prospectSelectData: PracticeProspect[];
}

const CallHistoryFilters = ({ prospectSelectData }: CallHistoryFiltersProps) => {
  const { user } = useAppSelector((state) => state.auth);
  const isSalesRep = user?.role === OrgRoles.SALES_REP;

  // Retrieve filters from URL parameters.
  const filters: CallHistoryFiltersType = useGetFiltersFromParams();

  // Hook to update filter parameters in the URL.
  const updateFilterParams = useUpdateFilterParams();

  // Fetch users and phone numbers for the caller options.
  const { data: userData } = useGetUsersForSelectQuery();

  // Memoize caller and prospect options to avoid unnecessary recalculations.
  const callerOptions = useMemo(() => getCallerOptions(userData), [userData]);
  const prospectOptions = useMemo(() => getProspectOptions(prospectSelectData), [prospectSelectData]);

  // Find the selected caller option based on the current filters.
  const selectedCaller = useMemo(
    () => getSelectedOption(callerOptions, filters.caller),
    [callerOptions, filters.caller]
  );

  // Find the selected prospect option based on the current filters.
  const selectedProspect = useMemo(
    () => getSelectedOption(prospectOptions, filters.prospect),
    [prospectOptions, filters.prospect]
  );

  return (
    <>
      {/* Only show the Caller filter if the user is not a Sales Representative */}
      {!isSalesRep && (
        <SidebarSelectFilter
          options={callerOptions}
          placeholder="Select caller"
          selected={selectedCaller}
          title="Caller"
          onChange={(newValue?: string) => updateFilterParams(CallHistoryFilterKeys.CALLER, newValue)}
        />
      )}
      {/* Prospect filter */}
      <SidebarSelectFilter
        options={prospectOptions}
        placeholder="Select prospect"
        selected={selectedProspect}
        title="Prospect"
        onChange={(newValue?: string) => updateFilterParams(CallHistoryFilterKeys.PROSPECT, newValue)}
      />
      {/* Call Date filter */}
      <SidebarDateFilter
        title="Call Date"
        onChange={(newValue?: string) => updateFilterParams(CallHistoryFilterKeys.CALL_DATE, newValue)}
        selected={parseOptionalSelectOption(filters.callDate)}
      />
      {/* Prospect Type filter */}
      <SidebarSelectFilter
        options={Object.values(ProspectType).map(parseRequiredSelectOption)}
        placeholder="Select prospect type"
        selected={parseOptionalSelectOption(filters.prospectType)}
        title="Prospect Type"
        onChange={(newValue?: string) => updateFilterParams(CallHistoryFilterKeys.PROSPECT_TYPE, newValue)}
      />
      {/* Call Objective filter */}
      <SidebarSelectFilter
        options={Object.values(CallObjective).map(parseRequiredSelectOption)}
        placeholder="Select call objective"
        selected={parseOptionalSelectOption(filters.callObjective)}
        title="Call Objective"
        onChange={(newValue?: string) => updateFilterParams(CallHistoryFilterKeys.CALL_OBJECTIVE, newValue)}
      />
    </>
  );
};

export default CallHistoryFilters;
