import { NUM_LOADING_SKELETONS, USER_ROW_HEIGHT } from '../../../../../../constants';
import { useGetCandidatesQuery } from '../../../../../../services';
import { ComponentSize, TextColor } from '../../../../../../types';
import { Divider, Skeleton, Typography } from '../../../../../shared';
import CandidateRow from './CandidateRow';

const CandidateTable = () => {
  const { data: candidates = [], isLoading } = useGetCandidatesQuery();

  // Maximum height for displaying user rows.
  // Show 3 user rows at a time.
  const maxHeight = USER_ROW_HEIGHT * 3;

  return (
    <div className="display-scrollbar overflow-auto" style={{ maxHeight }}>
      {isLoading &&
        Array.from({ length: NUM_LOADING_SKELETONS }, (_, index) => (
          <div key={index}>
            <div className="flex justify-between py-2">
              <Skeleton size={ComponentSize.MEDIUM} />
            </div>
            {index < NUM_LOADING_SKELETONS - 1 && <Divider />}
          </div>
        ))}
      {!isLoading && !candidates.length && (
        <div className="flex flex-col items-center justify-center py-4">
          <Typography color={TextColor.SECONDARY}>Your organization has no candidates at the moment.</Typography>
          <Typography color={TextColor.SECONDARY}>Add candidates through the form above.</Typography>
        </div>
      )}
      {!isLoading &&
        !!candidates.length &&
        candidates.map(({ candidateId, ...candidate }, index) => (
          <div key={candidateId}>
            <CandidateRow candidateId={candidateId} {...candidate} />
            {index < candidates.length - 1 && <Divider />}
          </div>
        ))}
    </div>
  );
};

export default CandidateTable;
