import React from 'react';
import { useNavigate } from 'react-router-dom';
import { TextButton, Typography, TypographyWeight } from '../../components';
import { ComponentSize, TextColor } from '../../types';

const NotFound: React.FC = () => {
  const navigate = useNavigate();

  return (
    <div className="flex h-screen items-center justify-center">
      <div className="flex flex-col items-center gap-4">
        <Typography color={TextColor.DESTRUCTIVE} weight={TypographyWeight.BOLD} className="!text-6xl">
          404
        </Typography>
        <div className="flex flex-col items-center gap-2">
          <Typography weight={TypographyWeight.SEMI_BOLD} color={TextColor.SECONDARY} className="!text-2xl">
            Page Not Found
          </Typography>
          <Typography color={TextColor.SECONDARY}>
            Sorry, the page you are looking for appears to have been moved, deleted or does not exist.
          </Typography>
        </div>
        <TextButton onClick={() => navigate('/')} text="Back to Homepage" size={ComponentSize.MEDIUM} />
      </div>
    </div>
  );
};

export default NotFound;
