import clsx from 'clsx';
import { DividerProps, DividerType } from './Divider.types';

/** Divider component for rendering a horizontal or vertical divider with optional text. */
const Divider = ({ text, type = DividerType.HORIZONTAL }: DividerProps) => {
  return (
    <div className={clsx('divider m-0', type === DividerType.HORIZONTAL ? 'divider-vertical' : 'divider-horizontal')}>
      {text}
    </div>
  );
};

export default Divider;
