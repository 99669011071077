import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { getAuth0ClientInstance } from '../context/CustomAuth0Provider';
import {
  AddCommentPayload,
  ApiTagTypes,
  AppUser,
  Call,
  CallResponseData,
  CallsPayload,
  CallsResponseData,
  Candidate,
  CandidatesResponse,
  CommentsResponseData,
  ConfirmUserPhoneNumberPayload,
  CreateCandidatePayload,
  EditUserPhoneNumberPayload,
  EditUserRolePayload,
  InitiateCallPayload,
  InitiateCallResponse,
  InitiateCallResponseData,
  Invite,
  InviteUserPayload,
  InvitesResponse,
  OrgUsersResponse,
  PhoneNumbersResponse,
  PostSuccessResponse,
  PracticeProspect,
  PracticeProspectsPayload,
  PracticeProspectsResponseData,
  UsersSelectResponse,
} from '../types';
import { getParsedAuth0User, getUrlWithPagination } from '../utils';

// Setup RTK Query API service with base configurations.
export const api = createApi({
  reducerPath: 'api',
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_API_BASE_URL, // Set the base URL for all queries.
    prepareHeaders: async (headers) => {
      const auth0Client = getAuth0ClientInstance();
      const token = await auth0Client.getAccessTokenSilently();
      if (token) {
        // If the token exists, set the Authorization header.
        headers.set('Authorization', `Bearer ${token}`);
      }
      return headers;
    },
  }),
  tagTypes: Object.values(ApiTagTypes),
  endpoints: (builder) => ({
    // -- CANDIDATES --
    createCandidate: builder.mutation<PostSuccessResponse, CreateCandidatePayload>({
      query: (candidateDate) => ({
        url: `/candidates/create`,
        method: 'POST',
        body: candidateDate,
      }),
      invalidatesTags: [ApiTagTypes.CANDIDATES],
    }),
    deleteCandidate: builder.mutation<PostSuccessResponse, string>({
      query: (candidateId) => ({
        url: `/candidates/${candidateId}/delete`,
        method: 'POST',
      }),
      invalidatesTags: [ApiTagTypes.CANDIDATES],
    }),
    getCandidates: builder.query<Candidate[], void>({
      query: () => '/candidates',
      transformResponse: (response: CandidatesResponse) => response.data.candidates,
      providesTags: [ApiTagTypes.CANDIDATES],
    }),
    // -- COMMENTS --
    addComment: builder.mutation<PostSuccessResponse, AddCommentPayload>({
      query: (commentData) => ({
        url: `/comments/create`,
        method: 'POST',
        body: commentData,
      }),
      invalidatesTags: [ApiTagTypes.COMMENTS],
    }),
    deleteComment: builder.mutation<PostSuccessResponse, string>({
      query: (commentId) => ({
        url: `/comments/${commentId}/delete`,
        method: 'POST',
      }),
      invalidatesTags: [ApiTagTypes.COMMENTS],
    }),
    getComments: builder.query<CommentsResponseData, string>({
      query: (callSid) => `/comments/${callSid}`,
      transformResponse: (response: { data: CommentsResponseData }) => response.data,
      providesTags: [ApiTagTypes.COMMENTS],
    }),
    // -- CURRENT USER --
    getPhoneNumbers: builder.query<string[], void>({
      query: () => '/users/me/phone-numbers',
      transformResponse: (response: PhoneNumbersResponse) => response.data.phoneNumbers,
      providesTags: [ApiTagTypes.PHONE_NUMBERS],
    }),
    // -- ORG USERS --
    getUsers: builder.query<AppUser[], void>({
      query: () => '/users',
      transformResponse: (response: OrgUsersResponse) => {
        const users = response.data.users;
        const parsedUsers = users.map((user) => getParsedAuth0User(user));
        return parsedUsers;
      },
      providesTags: [ApiTagTypes.USERS],
    }),
    disableUser: builder.mutation<PostSuccessResponse, { id: string }>({
      query: ({ id }) => ({
        url: `/users/${id}/disable`,
        method: 'POST',
      }),
      async onQueryStarted(props, { dispatch, queryFulfilled }) {
        await queryFulfilled;
        // Invalidate cache after a delay because Auth0 does not update immediately.
        setTimeout(() => {
          dispatch(api.util.invalidateTags([ApiTagTypes.USERS]));
        }, 3000);
      },
    }),
    initiateCall: builder.mutation<InitiateCallResponseData, InitiateCallPayload>({
      query: ({ to }) => ({
        url: `/twilio/initiate-call`,
        method: 'POST',
        body: { to },
      }),
      transformResponse: (response: InitiateCallResponse) => response.data,
    }),
    confirmUserPhoneNumber: builder.mutation<PostSuccessResponse, ConfirmUserPhoneNumberPayload>({
      query: ({ id, phoneNumber }) => ({
        url: `/phone-verification/${id}/verify`,
        method: 'POST',
        body: { phoneNumber },
      }),
      invalidatesTags: [ApiTagTypes.PHONE_NUMBERS],
    }),
    deleteUserPhoneNumber: builder.mutation<PostSuccessResponse, EditUserPhoneNumberPayload>({
      query: ({ id, phoneNumber }) => ({
        url: `/users/${id}/phone-numbers/delete`,
        method: 'POST',
        body: { phoneNumber },
      }),
      invalidatesTags: [ApiTagTypes.PHONE_NUMBERS],
    }),
    editUserRole: builder.mutation<PostSuccessResponse, EditUserRolePayload>({
      query: ({ id, ...editData }) => ({
        url: `/users/${id}/role/edit`,
        method: 'POST',
        body: editData,
      }),
      invalidatesTags: [ApiTagTypes.USERS],
    }),
    enableUser: builder.mutation<PostSuccessResponse, { id: string }>({
      query: ({ id }) => ({
        url: `/users/${id}/enable`,
        method: 'POST',
      }),
      async onQueryStarted(props, { dispatch, queryFulfilled }) {
        await queryFulfilled;
        // Invalidate cache after a delay because Auth0 does not update immediately.
        setTimeout(() => {
          dispatch(api.util.invalidateTags([ApiTagTypes.USERS]));
        }, 3000);
      },
    }),
    // -- CALLS --
    getCall: builder.query<Call, string>({
      query: (id) => ({
        url: `/calls/${id}`,
        method: 'GET',
      }),
      transformResponse: (response: { data: CallResponseData }) => response.data.call,
    }),
    getCalls: builder.mutation<CallsResponseData, CallsPayload>({
      query: ({ pagination, ...filters }) => ({
        url: getUrlWithPagination('/calls', pagination),
        method: 'POST',
        body: filters,
      }),
      transformResponse: (response: { data: CallsResponseData }) => response.data,
    }),
    // -- INVITES --
    getInvites: builder.query<Invite[], void>({
      query: () => '/invite',
      transformResponse: (response: InvitesResponse) => response.data.invitations,
      providesTags: [ApiTagTypes.INVITES],
    }),
    inviteUser: builder.mutation<PostSuccessResponse, InviteUserPayload>({
      query: ({ ...inviteData }) => ({
        url: `/invite`,
        method: 'POST',
        body: inviteData,
      }),
      invalidatesTags: [ApiTagTypes.INVITES],
    }),
    revokeInvitation: builder.mutation<PostSuccessResponse, { id: string }>({
      query: ({ id }) => ({
        url: `/invite/${id}/revoke`,
        method: 'POST',
      }),
      invalidatesTags: [ApiTagTypes.INVITES],
    }),
    // -- PROSPECTS --
    getPracticeProspects: builder.mutation<PracticeProspectsResponseData, PracticeProspectsPayload>({
      query: ({ pagination, ...filters }) => ({
        url: getUrlWithPagination('/prospects', pagination),
        method: 'POST',
        body: filters,
      }),
      transformResponse: (response: { data: PracticeProspectsResponseData }) => response.data,
    }),
    // -- SELECT --
    getPracticeProspectsForSelect: builder.query<PracticeProspect[], void>({
      query: () => '/prospects/select',
      transformResponse: (response: { data: PracticeProspectsResponseData }) => response.data.prospects,
    }),
    getUsersForSelect: builder.query<{ users: AppUser[]; phoneNumbers?: string[] }, void>({
      query: () => '/users/select',
      transformResponse: (response: UsersSelectResponse) => {
        const users = response.data.users;
        const parsedUsers = users.map((user) => getParsedAuth0User(user));
        const phoneNumbers = response.data.phoneNumbers;
        return { users: parsedUsers, phoneNumbers };
      },
    }),
  }),
});

// Export hooks for usage in functional components
export const {
  useCreateCandidateMutation,
  useDeleteCandidateMutation,
  useGetCandidatesQuery,
  useAddCommentMutation,
  useDeleteCommentMutation,
  useGetCommentsQuery,
  useDisableUserMutation,
  useInitiateCallMutation,
  useConfirmUserPhoneNumberMutation,
  useDeleteUserPhoneNumberMutation,
  useEditUserRoleMutation,
  useEnableUserMutation,
  useGetCallQuery,
  useGetCallsMutation,
  useGetInvitesQuery,
  useGetPhoneNumbersQuery,
  useGetPracticeProspectsMutation,
  useGetPracticeProspectsForSelectQuery,
  useGetUsersQuery,
  useGetUsersForSelectQuery,
  useInviteUserMutation,
  useRevokeInvitationMutation,
} = api;
