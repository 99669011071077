import WaveSurfer from 'wavesurfer.js';
import { useFeatureFlag } from '../../../hooks/useFeatureFlag';
import { CallProcessingStatus } from '../../../types';
import { LD_FeatureFlags } from '../../../types/launch-darkly.types';
import CommentButton from './CommentButton';
import ShareButton from './ShareButton';
import SpeedButton from './SpeedButton';

interface ActionButtonsProps {
  callSid: string;
  currentTime: number;
  waveSurfer: WaveSurfer;
  processingStatus?: CallProcessingStatus;
}

const ActionButtons = ({ callSid, waveSurfer, processingStatus, currentTime }: ActionButtonsProps) => {
  const detailedMediaPlayerViewFF = useFeatureFlag(LD_FeatureFlags.DETAILED_MEDIA_PLAYER_VIEW);
  const detailedMediaPlayerCommentsFF = useFeatureFlag(LD_FeatureFlags.RELEASE_DETAILED_MEDIA_PLAYER_COMMENTS);

  return (
    <div className="flex gap-2">
      <SpeedButton waveSurfer={waveSurfer} />
      {detailedMediaPlayerCommentsFF && (
        <CommentButton callSid={callSid} waveSurfer={waveSurfer} currentTime={currentTime} />
      )}
      {/* Only show Share if call processing has completed. */}
      {detailedMediaPlayerViewFF && processingStatus === CallProcessingStatus.PROCESSED && (
        <ShareButton callSid={callSid} waveSurfer={waveSurfer} currentTime={currentTime} />
      )}
    </div>
  );
};

export default ActionButtons;
