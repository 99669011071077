import { PracticeProspect } from '../../types';
import { formatDurationVerbose, snakeCaseToLabel } from '../../utils';
import CallStatsCard from './CallStatsCard';

const roundToTwoDecimals = (value: number): number => Math.round(value * 100) / 100;

const calculateTalkRatio = (talkTime: number, callDuration: number): number =>
  callDuration ? roundToTwoDecimals((talkTime / callDuration) * 100) : 0;

const calculateTalkSpeed = (wordsCount: number, talkTime: number): number =>
  talkTime ? Math.round((wordsCount / talkTime) * 60) : 0;

interface CallStatsPanelProps {
  practiceProspect: PracticeProspect;
  longestMonologueDuration?: number;
  fillerWordsCount?: number;
  wordsCount?: number;
  talkTime?: number;
  callDuration?: number;
}

const CallStatsPanel = ({
  practiceProspect,
  longestMonologueDuration = 0,
  fillerWordsCount = 0,
  wordsCount = 0,
  talkTime = 0,
  callDuration = 0,
}: CallStatsPanelProps) => {
  return (
    <div className="flex flex-col gap-4">
      <CallStatsCard
        title="Call Scenario"
        value={practiceProspect.objective}
        subtitle={`${snakeCaseToLabel(practiceProspect.type)} call`}
      />
      {/* Longest time representative spoke without letting the prospect speak. */}
      <CallStatsCard
        title="Longest Monologue"
        value={formatDurationVerbose(longestMonologueDuration)}
        subtitle="by representative"
      />
      {/* The % of the call the representative was speaking. */}
      <CallStatsCard
        title="Talk Ratio"
        value={`${calculateTalkRatio(talkTime, callDuration)}%`}
        subtitle="representative speaking"
      />
      {/* How many words per minute the representative spoke */}
      <CallStatsCard title="Talk Speed" value={calculateTalkSpeed(wordsCount, talkTime)} subtitle="words per minute" />
      {/* How many filler words the representative spoke per minute */}
      <CallStatsCard title="Filler Words" value={fillerWordsCount} subtitle="filler words per minute" />
    </div>
  );
};

export default CallStatsPanel;
