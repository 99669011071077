import clsx from 'clsx';
import { TextColor } from '../../../types';
import { FilledVariant, IconButton } from '../Button';
import { Icon } from '../Icons';
import { Portal } from '../Portal';
import { Typography, TypographySize, TypographyWeight } from '../Typography';
import { DIALOG_TYPE_TO_WIDTH } from './Dialog.constants';
import { DialogProps, DialogType } from './Dialog.types';

const Dialog = ({
  children,
  isOpen,
  description,
  hideCloseButton,
  padding,
  title,
  type = DialogType.DEFAULT,
  onClose,
}: DialogProps) => {
  return (
    <Portal>
      <dialog className="modal" open={isOpen} onClose={onClose}>
        <div
          className={clsx(
            'modal-box flex max-w-none flex-col rounded-lg bg-base-0 shadow-dialog',
            DIALOG_TYPE_TO_WIDTH[type]
          )}
          style={{ padding }}
        >
          {!hideCloseButton && onClose && (
            <div className="absolute right-2 top-2">
              <IconButton onClick={onClose} icon={Icon.CLOSE} variant={FilledVariant.UNFILLED} />
            </div>
          )}
          <Typography weight={TypographyWeight.SEMI_BOLD} size={TypographySize.H2}>
            {title}
          </Typography>
          {description && <Typography color={TextColor.SECONDARY}>{description}</Typography>}
          <div className={clsx('flex-grow overflow-auto', title || description ? 'mt-4' : '')}>{children}</div>
        </div>
      </dialog>
    </Portal>
  );
};

export default Dialog;
