import { JWT_NAMESPACE_APP_METADATA_CLAIM, JWT_NAMESPACE_ORG_CLAIM, JWT_NAMESPACE_ROLES_CLAIM } from '../constants';

export type Auth0AppMetaData = {
  onboarded?: {
    [key: string]: boolean;
  };
};

/** Represents an organization as stored in Auth0. */
export type Auth0Organization = {
  display_name: string; // Organization display name.
  name: string; // organization name written in an id form.
  id: string; // Organization ID.
  metadata: Auth0OrgMetadata; // Organization metadata.
};

export type Auth0OrgMetadata = {
  [key: string]: unknown;
  isHiringModuleEnabled?: boolean;
  license?: number;
};

/** Auth0 organization roles, ordered from top to bottom. */
export enum OrgRoles {
  SUPER_ADMIN = 'super-admin',
  ADMIN = 'admin',
  MANAGER = 'manager',
  SALES_REP = 'sales-rep',
}

export enum Permissions {
  // Can do all actions.
  ALL = '*:dashboard',
  // Can disable/enable a user in the organization.
  DISABLE_USER = 'disable:user',
  // Can edit a user in the organization or themselves (ie. Name, Phone numbers, etc).
  EDIT_USER = 'edit:user',
  // Can promote/denote a user in the organization.
  EDIT_USER_ROLE = 'edit-role:user',
  // Can invite a user to the organization.
  INVITE_USER = 'invite:user',
  // Can edit organization details (ie. Name, Logo, etc)
  EDIT_ORG = 'manage:org',
  // Can view all users in the organization.
  VIEW_ORG_USERS = 'view-users:org',
  // Can view all candidates in the organization.
  VIEW_CANDIDATE = 'view:candidate',
}

export type Auth0JwtClaims = {
  [JWT_NAMESPACE_APP_METADATA_CLAIM]: Auth0AppMetaData;

  // Extracting the organization data claim
  [JWT_NAMESPACE_ORG_CLAIM]: Auth0Organization;

  // Extracting the role data claim
  [JWT_NAMESPACE_ROLES_CLAIM]: OrgRoles[]; // Array of roles associated with the user.

  permissions: Permissions[]; // Array of permissions associated with the user.
};
