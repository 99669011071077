import { SelectOption } from '../components';
import { PracticeProspect } from '../types';
import { snakeCaseToLabel } from './string.utils';

/**
 * Parses a required value into a SelectOption shape (label and value).
 */
export const parseRequiredSelectOption = (value: string): SelectOption => ({ label: snakeCaseToLabel(value), value });

/**
 * Parses an optional value into a SelectOption shape (label and value).
 * If the value is undefined, it returns undefined.
 */
export const parseOptionalSelectOption = (value?: string): SelectOption | undefined =>
  value ? { label: snakeCaseToLabel(value), value } : undefined;

/**
 * Creates prospect options in SelectOption shape from prospect data.
 */
export const getProspectOptions = (prospectSelectData: PracticeProspect[]): SelectOption[] => {
  return prospectSelectData.map((prospect) => ({
    value: prospect.personaId,
    label: `${prospect.firstName} ${prospect.lastName}`,
  }));
};

/**
 * Finds the selected option from the options list based on the selected value.
 */
export const getSelectedOption = (
  options: { value: string; label: string }[],
  selectedValue?: string
): SelectOption | undefined => {
  return options.find((option) => option.value === selectedValue);
};
