import { useCallback } from 'react';

/**
 * Custom hook to handle dialing a phone number.
 */
const useDial = (onSuccess: () => void) => {
  // Dials the phone number by updating the window location.
  const onDial = useCallback(
    (phoneNumber?: string) => {
      if (phoneNumber) {
        // Initiate a phone call to the provided phone number.
        window.location.href = `tel:${phoneNumber}`;
        // Execute onSuccess callback.
        onSuccess();
      }
    },
    [onSuccess]
  );

  return onDial;
};

export default useDial;
