import clsx from 'clsx';
import dayjs from 'dayjs';
import { SIDEBAR_WIDTH } from '../../constants';
import { useAppSelector } from '../../hooks';
import { Call, ComponentSize, DateFormat, TextColor, TimeFormat } from '../../types';
import { getCallerInfo } from '../../utils';
import { Avatar, AvatarGroup, Typography, TypographySize, TypographyWeight } from '../shared';

/**
 * Transforms a date string or Date object to a string in the format "on mm/dd/yy at hh:mm".
 */
const formatDateTime = (input: string | Date): string => {
  const date = typeof input === 'string' ? new Date(input) : input;

  if (!(date instanceof Date) || isNaN(date.getTime())) {
    throw new Error('Invalid date object');
  }

  return `on ${dayjs(date).format(DateFormat.FULL_DATE)} at ${dayjs(date).format(TimeFormat.FULL_TIME)}`;
};

interface CallerSectionProps {
  call: Call;
}

const CallerSection = ({ call }: CallerSectionProps) => {
  const { user } = useAppSelector((state) => state.auth);

  if (!user) return null;

  const { name: callerName, picture: callerPicture } = getCallerInfo(call, user);
  const prospectName = `${call.practiceProspect.firstName} ${call.practiceProspect.lastName}`;
  const formattedCallDate = formatDateTime(call.startTime);

  return (
    <div className={clsx('box-border flex h-full items-center gap-4', SIDEBAR_WIDTH)}>
      <AvatarGroup>
        <Avatar label={prospectName} key="prospect-avatar" size={ComponentSize.LARGE} />
        <Avatar label={callerName} key="caller-avatar" size={ComponentSize.LARGE} imgSrc={callerPicture} />
      </AvatarGroup>
      <div className="flex flex-col gap-1">
        <Typography weight={TypographyWeight.MEDIUM} size={TypographySize.H5}>
          {callerName}
        </Typography>
        <Typography color={TextColor.SECONDARY} size={TypographySize.CAPTION}>
          calling {prospectName} {formattedCallDate}
        </Typography>
      </div>
    </div>
  );
};

export default CallerSection;
