import { Dispatch, SetStateAction, useCallback } from 'react';
import { FetchBaseQueryError } from '@reduxjs/toolkit/query';
import { useHandleApiResponse, useToast } from '../../../hooks';
import { Dialog, DialogType, AlertType, TextButton, Typography, TypographySize, TypographyWeight } from '../../shared';
import { useConfirmUserPhoneNumberMutation } from '../../../services/api.services';
import { ComponentSize, ComponentType } from '../../../types';
import { checkPhoneNumberError } from '../../../utils';

interface PhoneVerificationProps {
  isOpen: boolean;
  phoneVerificationData: {
    userId: string;
    phoneNumber: string;
    verificationCode: string;
  };
  setError: Dispatch<SetStateAction<string>>;
  onHide: () => void;
  onVerify: () => void;
}

const ERROR_MSG = 'Failed to add phone number';
const SUCCESS_MSG = 'Phone number added successfully';

const PhoneVerificationModal = ({
  phoneVerificationData,
  onHide,
  setError,
  isOpen,
  onVerify,
}: PhoneVerificationProps) => {
  const { userId, phoneNumber, verificationCode } = phoneVerificationData;

  // Custom hooks
  const { showToast } = useToast();
  const handleApiResponse = useHandleApiResponse();

  // Mutations
  const [confirmUserPhoneNumber, { isLoading }] = useConfirmUserPhoneNumberMutation();

  const onError = useCallback(
    (error: FetchBaseQueryError) => {
      const errorMsg = checkPhoneNumberError(error);
      if (errorMsg) {
        setError(errorMsg);
        showToast({ message: errorMsg, type: AlertType.ERROR });
      }
      onHide();
    },
    [checkPhoneNumberError, showToast, onHide]
  );

  const onSuccess = useCallback(() => {
    onVerify();
    onHide();
  }, [onVerify, onHide]);

  // Handles saving edits to the user profile.
  const handleConfirmUserPhoneNumber = useCallback(async () => {
    try {
      const payload = { id: userId, phoneNumber };
      const response = await confirmUserPhoneNumber(payload);
      handleApiResponse({ response, errorMsg: ERROR_MSG, successMsg: SUCCESS_MSG, onError, onSuccess });
    } catch (error) {
      console.error(`${ERROR_MSG}: `, error);
      showToast({ message: ERROR_MSG, type: AlertType.ERROR });
    }
  }, [confirmUserPhoneNumber, handleApiResponse, onError, showToast, userId, phoneNumber]);

  return (
    <Dialog
      title="Add phone number to account"
      isOpen={isOpen}
      onClose={onHide}
      type={DialogType.CONFIRM}
      padding="32px"
    >
      <div className="my-6 mb-10 text-center">
        <Typography weight={TypographyWeight.SEMI_BOLD} size={TypographySize.H4}>
          Calling {phoneNumber} ...
        </Typography>
      </div>
      <div className="mb-6 text-center">
        <Typography>
          Dial the code <b>{verificationCode}</b> when prompted.
        </Typography>
      </div>
      <TextButton
        fullWidth
        text="Done"
        loading={isLoading}
        size={ComponentSize.LARGE}
        type={ComponentType.PRIMARY}
        onClick={handleConfirmUserPhoneNumber}
      />
    </Dialog>
  );
};

export default PhoneVerificationModal;
