import { Dispatch, SetStateAction, useCallback } from 'react';
import { useHandleApiResponse, useToast } from '../../../../../hooks';
import { useEnableUserMutation } from '../../../../../services';
import { AppUser, OrgRoles } from '../../../../../types';
import { AlertType, Typography, TypographyWeight } from '../../../../shared';
import ConfirmModal from '../../../ConfirmModal';

const ERROR_MSG = 'Failed to enable user';

interface EnableConfirmModalProps {
  isOpen: boolean;
  user: { id: string; name?: string; role?: OrgRoles };
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  setUsers: Dispatch<SetStateAction<AppUser[]>>;
}

const EnableConfirmModal = ({ isOpen, user, setIsOpen, setUsers }: EnableConfirmModalProps) => {
  // Custom hooks
  const { showToast } = useToast();
  const handleApiResponse = useHandleApiResponse();

  // Mutations
  const [enableUser, { isLoading }] = useEnableUserMutation();

  const handleReEnableUser = useCallback(async () => {
    try {
      const response = await enableUser({ id: user.id });

      const onSuccess = () => {
        // Optimistically update user state.
        setUsers((prev) =>
          prev.map((prevUser) => {
            return prevUser.id === user.id ? { ...prevUser, disabled: false } : prevUser;
          })
        );
        setIsOpen(false);
      };

      await handleApiResponse({ response, errorMsg: ERROR_MSG, onSuccess });
    } catch (error) {
      console.error(`${ERROR_MSG}: `, error);
      showToast({ message: ERROR_MSG, type: AlertType.ERROR });
    }
  }, [handleApiResponse, setIsOpen, setUsers, showToast]);

  return (
    <ConfirmModal
      buttonText="Enable"
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      onConfirm={() => void handleReEnableUser()}
      title="Confirm enable user"
      isLoading={isLoading}
      confirmText={
        <>
          Are you sure you want to re-enable <Typography weight={TypographyWeight.MEDIUM}>{user.name}</Typography>?
        </>
      }
    />
  );
};

export default EnableConfirmModal;
