import { FloatingPortal, useMergeRefs } from '@floating-ui/react';
import { forwardRef } from 'react';
import { useTooltipContext } from './TooltipContext';

/**
 * TooltipContent component that renders the content of the tooltip.
 * It uses a portal to render the tooltip in a different part of the DOM.
 */
const TooltipContent = forwardRef<HTMLDivElement, React.HTMLProps<HTMLDivElement>>(function TooltipContent(
  { style, ...props },
  propRef
) {
  const context = useTooltipContext();
  const ref = useMergeRefs([context.refs.setFloating, propRef]);

  // Do not render if tooltip is not open or no children are provided.
  if (!context.open || !props.children) return null;

  return (
    <FloatingPortal>
      <div
        className="z-[9999] rounded-md bg-gray-800 p-2 text-xs text-white"
        ref={ref}
        style={{
          ...context.floatingStyles,
          ...style,
        }}
        {...context.getFloatingProps(props)}
      />
    </FloatingPortal>
  );
});

export default TooltipContent;
