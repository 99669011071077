import { ComponentSize } from '../../../types';

export enum BadgeColor {
  DEFAULT = 'default',
  ERROR = 'error',
  SUCCESS = 'success',
}

export enum BadgeType {
  DEFAULT = 'default',
  OUTLINE = 'outline',
}

export type BadgeSize = ComponentSize.SMALL | ComponentSize.MEDIUM | ComponentSize.LARGE;

export interface BadgeProps {
  label: string;
  color?: BadgeColor;
  size?: BadgeSize;
  type?: BadgeType;
  width?: string | number;
}
