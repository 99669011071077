import { useAppSelector } from '../../../../../hooks';
import { Typography, TypographySize, TypographyWeight } from '../../../../shared';
import AddUser from './AddUser';
import UserTable from './UserTable';

const OrganizationTab = () => {
  const organization = useAppSelector((state) => state.auth.organization);

  if (!organization) return null;

  return (
    <div className="flex w-full flex-col gap-4">
      <Typography weight={TypographyWeight.SEMI_BOLD} size={TypographySize.H5}>
        {organization.name}
      </Typography>
      <div className="flex flex-col gap-4">
        <AddUser />
        <UserTable />
      </div>
    </div>
  );
};

export default OrganizationTab;
