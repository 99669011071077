import { Getter } from '@tanstack/table-core';
import { ProspectColumnData, ProspectType } from '../../../types';
import { Badge, BadgeType } from '../Badge';
import { DataTableColumnDef } from '../DataTable';
import { UserBadge } from '../UserBadge';

// Factory function for the prospect column
export const createProspectColumn = <T extends { prospect: ProspectColumnData }>(): DataTableColumnDef<T> => ({
  accessorKey: 'prospect',
  width: '25%',
  header: 'Prospect',
  cell: ({ getValue }: { getValue: Getter<ProspectColumnData> }) => {
    const { name, jobTitle, company } = getValue();
    return <UserBadge title={name} subtitle={`${company} | ${jobTitle}`} />;
  },
});

// Factory function for the prospect type column
export const createProspectTypeColumn = <T extends { prospectType: ProspectType }>(): DataTableColumnDef<T> => ({
  accessorKey: 'prospectType',
  header: 'Prospect Type',
  cell: ({ getValue }: { getValue: Getter<ProspectType> }) => <Badge label={getValue()} type={BadgeType.OUTLINE} />,
});

// Factory function for the call objective column
export const createCallObjectiveColumn = <T extends { callObjective: string }>(): DataTableColumnDef<T> => ({
  accessorKey: 'callObjective',
  header: 'Call Objective',
});
