import { ProspectType } from './api.types';
import { DateOptions } from './date.types';

/**
 * Enum to define the keys used for practice filters in the URL and state.
 */
export enum PracticeFilterKeys {
  CALL_OBJECTIVE = 'callObjective',
  DATE_CREATED = 'dateCreated',
  LAST_UPDATED = 'lastUpdated',
  PROSPECT = 'prospect',
  PROSPECT_ACCOUNT = 'prospectAccount',
  PROSPECT_TYPE = 'prospectType',
  SEARCH = 'search',
}

/**
 * Enum to define the keys used for call filters in the URL and state.
 */
export enum CallHistoryFilterKeys {
  CALL_OBJECTIVE = 'callObjective',
  CALL_DATE = 'callDate',
  CALLER = 'caller',
  PROSPECT = 'prospect',
  PROSPECT_TYPE = 'prospectType',
}

export type FilterKeys = CallHistoryFilterKeys | PracticeFilterKeys;

/**
 * Type definition for the filters used in the practice page.
 */
export type PracticeFilters = {
  [PracticeFilterKeys.CALL_OBJECTIVE]?: string;
  [PracticeFilterKeys.DATE_CREATED]?: DateOptions;
  [PracticeFilterKeys.LAST_UPDATED]?: DateOptions;
  [PracticeFilterKeys.PROSPECT]?: string;
  [PracticeFilterKeys.PROSPECT_ACCOUNT]?: string;
  [PracticeFilterKeys.PROSPECT_TYPE]?: ProspectType;
  [PracticeFilterKeys.SEARCH]?: string;
};

/**
 * Type definition for the filters used in the call page.
 */
export type CallHistoryFilters = {
  [CallHistoryFilterKeys.CALL_OBJECTIVE]?: string;
  [CallHistoryFilterKeys.CALL_DATE]?: DateOptions;
  [CallHistoryFilterKeys.CALLER]?: string;
  [CallHistoryFilterKeys.PROSPECT]?: string;
  [CallHistoryFilterKeys.PROSPECT_TYPE]?: ProspectType;
};
