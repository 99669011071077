import { useAuth0 } from '@auth0/auth0-react';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import fullyRampedLogo from '../../assets/logo-light.png';
import {
  Avatar,
  Dialog,
  Divider,
  PhoneNumberInput,
  TextButton,
  TextInput,
  Typography,
  TypographySize,
  TypographyWeight,
} from '../../components';
import { PhoneVerificationModal } from '../../components/modals/PhoneVerificationModal';
import { AppRoutes } from '../../constants';
import { useAppDispatch, useAppSelector } from '../../hooks';
import { setIsOnboarded } from '../../redux/reducers';
import { useInitiateCallMutation } from '../../services';
import { Alignment, ComponentSize, ComponentType } from '../../types';
import { splitName } from '../../utils';

const FinishSetupPage = () => {
  const { logout } = useAuth0();
  const navigate = useNavigate();

  const dispatch = useAppDispatch();
  const user = useAppSelector((state) => state.auth.user);

  const [firstName, lastName] = splitName(user?.name);

  // State for form fields and their errors.
  const [countryCode, setCountryCode] = useState('+1');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [phoneNumberError, setPhoneNumberError] = useState('');
  const [verificationCode, setVerificationCode] = useState('');
  const [isVerificationModalOpen, setIsVerificationModalOpen] = useState(false);

  const [initiateCall, { isLoading }] = useInitiateCallMutation();

  if (!user?.id) return null;

  // Handler for the "Finish" button click.
  const onFinish = async () => {
    // Validate form fields.
    if (!phoneNumber.length) {
      setPhoneNumberError('Field cannot be empty');
      return;
    }

    if (countryCode === '+') {
      setPhoneNumberError('Country code is required');
      return;
    }

    //Regex check for invalid phone numbers
    const newPhoneNumber = countryCode + phoneNumber;
    if (!/^\+\d{10,15}$/.test(newPhoneNumber)) {
      setPhoneNumberError('Invalid phone number');
      return;
    }

    // Initiate a phone verification call.
    const call = await initiateCall({ to: newPhoneNumber });

    if (call.error) {
      setPhoneNumberError('Failed to initiate a phone verification call, please check your phone number');
      return;
    }

    // If the call is successful, show the phone verification modal.
    if (call.data) {
      setVerificationCode(call.data.verificationCode);
      setIsVerificationModalOpen(true);
    }
  };

  const onVerify = () => {
    setIsVerificationModalOpen(false);
    dispatch(setIsOnboarded(true));
    navigate(AppRoutes.PRACTICE);
  };

  return (
    <div className="h-screen bg-base-200">
      <Dialog isOpen>
        <div className="flex flex-col items-center gap-4 p-6">
          <div className="flex flex-col items-center gap-6">
            <img src={fullyRampedLogo} alt="FullyRamped Logo" className="h-14 w-14" />
            <Typography size={TypographySize.H2} weight={TypographyWeight.SEMI_BOLD}>
              Finish setting up your account
            </Typography>
          </div>
          <div className="mb-4 flex w-full items-center gap-4">
            <Avatar size={ComponentSize.LARGE} label={user.name} imgSrc={user.picture} />
            <div className="flex flex-grow gap-2">
              <TextInput size={ComponentSize.MEDIUM} className="flex-grow" value={firstName} disabled />
              <TextInput size={ComponentSize.MEDIUM} className="flex-grow" value={lastName} disabled />
            </div>
          </div>
          <div className="flex flex-col items-center gap-4">
            <Typography alignment={Alignment.CENTER} size={TypographySize.H5}>
              Add the primary phone number you will use to make practice calls. You can add more later.
            </Typography>
            <PhoneNumberInput
              phoneNumber={phoneNumber}
              setPhoneNumber={setPhoneNumber}
              countryCode={countryCode}
              setCountryCode={setCountryCode}
              size={ComponentSize.MEDIUM}
              error={!!phoneNumberError.length}
              errorMsg={phoneNumberError}
              resetError={() => {
                if (phoneNumberError.length) {
                  setPhoneNumberError('');
                }
              }}
              isLoading={isLoading}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  onFinish();
                }
              }}
            />
          </div>
          <TextButton
            text="Finish"
            fullWidth
            onClick={onFinish}
            size={ComponentSize.MEDIUM}
            type={ComponentType.PRIMARY}
            loading={isLoading}
          />
          <Divider />
          <TextButton
            text="Logout"
            fullWidth
            onClick={logout}
            size={ComponentSize.MEDIUM}
            type={ComponentType.DESTRUCTIVE}
          />
          <PhoneVerificationModal
            isOpen={isVerificationModalOpen}
            setError={setPhoneNumberError}
            onHide={() => setIsVerificationModalOpen(false)}
            phoneVerificationData={{ userId: user.id, phoneNumber: countryCode + phoneNumber, verificationCode }}
            onVerify={onVerify}
          />
        </div>
      </Dialog>
    </div>
  );
};

export default FinishSetupPage;
