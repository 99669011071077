import { useEffect } from 'react';
import { Outlet, useParams } from 'react-router-dom';
import { MediaPlayer, NavBar, Sidebar } from '../../components';
import { useAppDispatch, useAppSelector, useIntercom } from '../../hooks';
import { setCurrentCallDetails } from '../../redux/reducers';

function Layout() {
  const { callSid } = useParams();
  const isDetailsPage = !!callSid;

  const dispatch = useAppDispatch();
  const { callSid: currentCallSid } = useAppSelector((state) => state.callHistory.currentCallDetails) || {};

  useIntercom();

  // Handles closing the media player on navigating away from the Call details page.
  useEffect(() => {
    if (!isDetailsPage) {
      dispatch(setCurrentCallDetails(undefined));
    }
  }, [isDetailsPage]);

  return (
    <div className="flex h-screen flex-col">
      <NavBar />
      <div className="flex flex-1 overflow-hidden">
        {!isDetailsPage && <Sidebar />}
        <div className="flex-1 overflow-auto bg-base-0 p-4">
          <Outlet />
        </div>
      </div>
      {currentCallSid && <MediaPlayer closable={!isDetailsPage} callSid={currentCallSid} />}
    </div>
  );
}

export default Layout;
