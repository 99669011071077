import { Getter, PaginationState } from '@tanstack/react-table';
import dayjs from 'dayjs';
import { useEffect, useMemo, useState } from 'react';
import {
  Badge,
  BadgeType,
  DataTableColumnDef,
  DataTableWithActions,
  Typography,
  createCallObjectiveColumn,
  createProspectColumn,
  createProspectTypeColumn,
} from '../../components';
import { PAGINATION_PAGE_SIZE } from '../../constants';
import { useGetFiltersFromParams } from '../../hooks';
import { useGetPracticeProspectsMutation } from '../../services';
import { DateFormat, PracticeFilters, ProspectRow } from '../../types';
import { formatPhoneNumber, getStartAndEndDatesFromDateOption, isWithinLastTwoDays } from '../../utils';
import useProspectActions from './useProspectActions';

const PracticePage = () => {
  // State to track the clicked row.
  const [clickedRowIndex, setClickedRowIndex] = useState<number | undefined>(undefined);
  // State for pagination settings.
  const [pagination, setPagination] = useState<PaginationState>({
    pageIndex: 1,
    pageSize: PAGINATION_PAGE_SIZE,
  });

  // Mutations
  // Fetch prospects with loading state management.
  const [getPracticeProspects, { data, isLoading }] = useGetPracticeProspectsMutation();
  const prospects = data?.prospects || [];
  const totalPages = data?.pagination.totalPages || 0;

  const clickedProspect = useMemo(() => {
    if (clickedRowIndex === undefined) return;
    return prospects[clickedRowIndex];
  }, [prospects, clickedRowIndex]);

  // Custom hooks
  const actions = useProspectActions(() => setClickedRowIndex(undefined), clickedProspect);
  const filters: PracticeFilters = useGetFiltersFromParams();

  // Fetch prospects whenever filters or pagination settings change.
  useEffect(() => {
    getPracticeProspects({
      accountName: filters.prospectAccount,
      createdDate: getStartAndEndDatesFromDateOption(filters.dateCreated),
      lastUpdated: getStartAndEndDatesFromDateOption(filters.lastUpdated),
      objective: filters.callObjective,
      personaId: filters.prospect,
      search: filters.search,
      type: filters.prospectType,
      pagination,
    });
  }, [filters, pagination]);

  // Parse prospects data to match the table format.
  // This includes transforming and combining data fields to fit the DataTable's expected row structure.
  const parsedProspects: ProspectRow[] = prospects.map((prospect) => ({
    phoneNumber: prospect.associatedPhoneNumber,
    prospect: {
      company: prospect.accountName,
      jobTitle: prospect.jobTitle,
      name: `${prospect.firstName} ${prospect.lastName}`,
    },
    notes: prospect.notes || '-',
    lastUpdated: prospect.lastModified,
    callObjective: prospect.objective,
    prospectType: prospect.type,
  }));

  // Define columns for the data table.
  const columns: DataTableColumnDef<ProspectRow>[] = [
    {
      // Column definition for displaying formatted phone numbers that are clickable for dialing.
      accessorKey: 'phoneNumber',
      header: 'Phone Number',
      cell: ({ getValue }: { getValue: Getter<string> }) => {
        const number = getValue();
        const formattedNumber = formatPhoneNumber(number);
        return (
          <Typography underline>
            <a href={`tel:${number}`} onClick={(e) => e.stopPropagation()}>
              {formattedNumber}
            </a>
          </Typography>
        );
      },
    },
    createProspectColumn(),
    createProspectTypeColumn(),
    createCallObjectiveColumn(),
    {
      // Column for any additional notes.
      accessorKey: 'notes',
      header: 'Notes',
    },
    {
      // Column for displaying the last modification date,
      // highlighting if it was within the last two days.
      accessorKey: 'lastUpdated',
      header: 'Last Updated',
      cell: ({ getValue }: { getValue: Getter<Date> }) => {
        const date = new Date(getValue());
        return (
          <div className="flex flex-wrap items-center gap-2">
            {dayjs(date).format(DateFormat.FULL_DATE)}
            {isWithinLastTwoDays(date) && <Badge label="New" type={BadgeType.OUTLINE} />}
          </div>
        );
      },
    },
  ];

  return (
    <DataTableWithActions
      actions={actions}
      columns={columns}
      isLoading={isLoading}
      data={parsedProspects}
      clickedRowIndex={clickedRowIndex}
      setClickedRowIndex={setClickedRowIndex}
      paginationControls={{ pagination, totalPages, setPagination }}
    />
  );
};

export default PracticePage;
