import { ComponentSize, TextColor } from '../../../types';
import { Avatar } from '../Avatar';
import { Typography } from '../Typography';
import { USER_BADGE_TEXT_SIZE } from './UserBadge.constants';
import { UserBadgeProps } from './UserBadge.types';

const UserBadge = ({ title, imgSrc, isInvitee, size = ComponentSize.SMALL, subtitle }: UserBadgeProps) => {
  return (
    <div className="flex items-center gap-2">
      <Avatar label={title} size={size} imgSrc={imgSrc} />
      <div className="flex flex-col">
        <div className="flex gap-2">
          <Typography size={USER_BADGE_TEXT_SIZE[size]}>{title}</Typography>
          {isInvitee && <Typography color={TextColor.SECONDARY}>Invited</Typography>}
        </div>
        {subtitle && (
          <Typography size={USER_BADGE_TEXT_SIZE[size]} color={TextColor.SECONDARY}>
            {subtitle}
          </Typography>
        )}
      </div>
    </div>
  );
};

export default UserBadge;
