import { ComponentType, TextColor } from '../../../types';
import { ButtonType } from './Button.types';
import { FilledVariant } from './IconButton';

export const getButtonContentColor = (disabled: boolean, type: ButtonType, filled = FilledVariant.FILLED) => {
  if (disabled) {
    return TextColor.SECONDARY;
  }

  if (type === ComponentType.DESTRUCTIVE) {
    return TextColor.DESTRUCTIVE;
  }

  if (filled === FilledVariant.FILLED) {
    return type === ComponentType.PRIMARY ? TextColor.WHITE : TextColor.PRIMARY;
  }

  return undefined;
};
