import clsx from 'clsx';
import React, { useCallback } from 'react';
import { ComponentSize, TextColor } from '../../../types';
import { Icon, Icons } from '../Icons';
import { Typography, TypographySize } from '../Typography';
import { DropdownItemProps } from './DropdownItem.types';

const DropdownItem = ({ label, active, destructive, disabled, icon, onClick }: DropdownItemProps) => {
  const textSize = TypographySize.CAPTION;
  const shouldDisable = disabled || !onClick;

  const getTextColor = useCallback(() => {
    if (shouldDisable) return TextColor.TERTIARY;
    if (destructive) return TextColor.DESTRUCTIVE;
    return TextColor.SECONDARY;
  }, [destructive, shouldDisable]);

  const renderIcon = useCallback(
    (icon: Icon) => <Icons icon={icon} color={getTextColor()} size={ComponentSize.X_SMALL} />,
    [icon, getTextColor]
  );

  return (
    <li onClick={onClick} className={clsx(shouldDisable && 'pointer-events-none')}>
      <div className={clsx(destructive && 'hover:bg-error', 'flex justify-between p-2')}>
        <div className="flex items-center gap-2">
          {icon && renderIcon(icon)}
          {typeof label === 'string' && (
            <Typography color={getTextColor()} size={textSize}>
              {label}
            </Typography>
          )}
          {typeof label === 'object' &&
            React.cloneElement(label, {
              color: label.props.color ?? getTextColor(),
              size: label.props.size ?? textSize,
            })}
        </div>
        {active && renderIcon(Icon.CHECK)}
      </div>
    </li>
  );
};

export default DropdownItem;
