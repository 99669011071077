import clsx from 'clsx';
import { useEffect, useRef, useState } from 'react';
import { ComponentSize } from '../../../types';
import { conditionalObject, convertToPx } from '../../../utils';
import { Tooltip, TooltipContent, TooltipTrigger } from '../Tooltip';
import { BADGE_COLOR, BADGE_SIZE, BADGE_TYPE } from './Badge.constants';
import { BadgeColor, BadgeProps, BadgeType } from './Badge.types';

const Badge = ({
  label,
  color = BadgeColor.DEFAULT,
  size = ComponentSize.SMALL,
  type = BadgeType.DEFAULT,
  width,
}: BadgeProps) => {
  const labelRef = useRef<HTMLDivElement>(null);
  const [isOverflowing, setIsOverflowing] = useState(false);

  useEffect(() => {
    if (labelRef.current) {
      setIsOverflowing(labelRef.current.scrollWidth > labelRef.current.clientWidth);
    }
  }, [label, width]);

  return (
    <Tooltip>
      <TooltipTrigger>
        <div className={clsx('badge text-center', BADGE_SIZE[size], BADGE_TYPE[type], BADGE_COLOR[color])}>
          <div
            ref={labelRef}
            style={{
              ...conditionalObject(!!width, {
                width: convertToPx(width),
                overflow: 'hidden',
                whiteSpace: 'nowrap',
                textOverflow: 'ellipsis',
              }),
            }}
          >
            {label}
          </div>
        </div>
      </TooltipTrigger>
      {/* Show tooltip only when the label is overflowing */}
      {isOverflowing && <TooltipContent>{label}</TooltipContent>}
    </Tooltip>
  );
};

export default Badge;
