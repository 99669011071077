import clsx from 'clsx';
import { FocusEvent, KeyboardEvent } from 'react';
import { ComponentSize, TextColor } from '../../../types';
import { Icons } from '../Icons';
import { Typography, TypographySize } from '../Typography';
import { INPUT_SIZE } from './TextInput.constants';
import './TextInput.css';
import { TextInputProps, TextInputType } from './TextInput.types';

const TextInput = ({
  className,
  disabled,
  startIcon,
  error,
  size = ComponentSize.SMALL,
  endElement,
  placeholder,
  type = TextInputType.TEXT,
  value = '',
  width,
  onBlur,
  onChange,
  onKeyDown,
}: TextInputProps) => {
  const handleOnBlur = (event: FocusEvent<HTMLInputElement>) => {
    if (onBlur) {
      event.stopPropagation();
      onBlur(event);
    }
  };

  const handleKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
    if (onKeyDown) {
      event.stopPropagation();
      onKeyDown(event);
    }
  };

  return (
    <div className={clsx(!width && 'w-full', className)} style={{ width }}>
      <label
        className={clsx(
          'input input-bordered flex items-center gap-2 border-base-300 bg-base-0 !outline-none',
          INPUT_SIZE[size],
          disabled && 'input-disabled cursor-auto border-transparent bg-base-100 text-neutral',
          error && '!border-error-content bg-error'
        )}
      >
        {startIcon && <Icons icon={startIcon} />}
        <input
          type={type}
          placeholder={placeholder}
          value={value}
          disabled={disabled}
          onChange={onChange}
          className="w-full"
          onKeyDown={handleKeyDown}
          onBlur={handleOnBlur}
          readOnly={!onChange || disabled}
          data-1p-ignore={type !== TextInputType.PASSWORD}
        />
        {endElement && endElement}
      </label>
      {typeof error === 'string' && (
        <div className="h-4">
          <Typography size={TypographySize.CAPTION} color={TextColor.DESTRUCTIVE}>
            {error}
          </Typography>
        </div>
      )}
    </div>
  );
};

export default TextInput;
