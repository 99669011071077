import { useLDClient, LDClient } from 'launchdarkly-react-client-sdk';
import FeatureFlagContext, { FeatureFlags } from './LaunchDarklyContext';
import { useEffect, useState } from 'react';
import { LD_FeatureFlags } from '../../types/launch-darkly.types';
import PropTypes from 'prop-types';

/**
 * FeatureFlagProvider is a React component that stores and provides feature flags to its children.
 */
export const FeatureFlagProvider: React.FC<{ children?: React.ReactNode }> = ({ children }) => {
  const ldClient = useLDClient() as LDClient;
  const [featureFlags, setFeatureFlags] = useState<FeatureFlags>({});

  useEffect(() => {
    if (!ldClient) return;

    const fetchFeatureFlag = async (flagKey: string) => {
      try {
        const flagValue = await ldClient.variation(flagKey, false);
        setFeatureFlags((prevFlags) => ({
          ...prevFlags,
          [flagKey]: flagValue,
        }));
      } catch (error) {
        console.error(`Error fetching feature flag ${flagKey}:`, error);
      }
    };

    const initializeFeatureFlags = async (flagKeys: string[]) => {
      for (const flagKey of flagKeys) {
        await fetchFeatureFlag(flagKey);
      }
    };

    const flagKeys = Object.values(LD_FeatureFlags);
    initializeFeatureFlags(flagKeys);
  }, [ldClient]);

  return <FeatureFlagContext.Provider value={featureFlags}>{children}</FeatureFlagContext.Provider>;
};

FeatureFlagProvider.propTypes = {
  children: PropTypes.node,
};
