import { Icon } from '../../Icons';
import { BaseButtonProps } from '../Button.types';

export enum FilledVariant {
  FILLED = 'filled',
  UNFILLED = 'unfilled',
}

export interface IconButtonProps extends BaseButtonProps {
  icon: Icon;
  variant?: FilledVariant;
}
