import { ChangeEventHandler, FocusEventHandler, KeyboardEventHandler } from 'react';
import { ComponentSize } from '../../../types';
import { Icon } from '../Icons';

export enum TextInputType {
  EMAIL = 'email',
  NUMBER = 'number',
  PASSWORD = 'password',
  TEXT = 'text',
}

export interface TextInputProps {
  className?: string;
  disabled?: boolean;
  endElement?: React.ReactNode;
  error?: boolean | string;
  size?: ComponentSize;
  startIcon?: Icon;
  placeholder?: string;
  type?: TextInputType;
  value?: string;
  width?: string | number;
  onBlur?: FocusEventHandler<HTMLInputElement>;
  onChange?: ChangeEventHandler<HTMLInputElement>;
  onKeyDown?: KeyboardEventHandler<HTMLInputElement>;
}
