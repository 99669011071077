import { FetchBaseQueryError } from '@reduxjs/toolkit/query';
import { PaginationState } from '@tanstack/react-table';
import { PostErrorResponse } from '../types';

/**
 * Parses error response to identify and return a suitable error message.
 */
export const checkPhoneNumberError = (error: FetchBaseQueryError): string | undefined => {
  const fetchError = error.data as PostErrorResponse;
  if (!fetchError.message) return;

  if (fetchError.message.includes('Validation error')) {
    return 'Invalid phone number';
  } else if (fetchError.message.includes('Phone number already exists')) {
    return 'This phone number is already associated with another user';
  } else if (fetchError.message.includes('not verified')) {
    return 'Phone number was not verified';
  } else {
    return fetchError.message;
  }
};

export const checkInviteUserError = (error: FetchBaseQueryError): string | undefined => {
  const fetchError = error.data as PostErrorResponse;
  if (!fetchError.message) return;

  if (fetchError.message.includes('Validation error')) {
    return 'Invalid email';
  } else {
    return fetchError.message;
  }
};

export const getUrlWithPagination = (url: string, pagination?: PaginationState) => {
  if (!pagination) {
    return url;
  } else {
    return `${url}?page=${pagination.pageIndex}&perPage=${pagination.pageSize}`;
  }
};
