import clsx from 'clsx';
import { useCallback } from 'react';
import { ComponentSize, ComponentType } from '../../../../types';
import { Icon, Icons } from '../../Icons';
import { Tooltip, TooltipContent, TooltipTrigger } from '../../Tooltip';
import { Typography } from '../../Typography';
import { BUTTON_SIZE, BUTTON_TYPE } from '../Button.constants';
import '../Button.css';
import { getButtonContentColor } from '../Button.utils';
import ButtonContent from '../ButtonContent';
import { TextButtonProps } from './TextButton.types';

const TextButton = ({
  active = false,
  text,
  disabled = false,
  fullWidth = false,
  endIcon,
  startIcon,
  loading,
  size = ComponentSize.SMALL,
  tooltip,
  type = ComponentType.SECONDARY,
  onClick,
}: TextButtonProps) => {
  const shouldDisable = disabled || loading || !onClick;

  const handleOnClick = onClick
    ? (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        e.stopPropagation();
        e.preventDefault();
        onClick();
      }
    : undefined;

  const renderIcon = useCallback(
    (icon: Icon) => <Icons icon={icon} color={getButtonContentColor(shouldDisable, type)} size={size} />,
    [shouldDisable, type]
  );

  return (
    <Tooltip>
      <TooltipTrigger fullWidth={fullWidth}>
        <div
          className={clsx(
            'btn relative',
            fullWidth ? 'btn-block' : 'w-fit',
            BUTTON_SIZE[size],
            BUTTON_TYPE[type],
            shouldDisable && 'btn-disabled',
            active && 'btn-active'
          )}
          onClick={handleOnClick}
        >
          <ButtonContent loading={loading}>
            {startIcon && renderIcon(startIcon)}
            <Typography color={getButtonContentColor(shouldDisable, type)}>{text}</Typography>
            {endIcon && renderIcon(endIcon)}
          </ButtonContent>
        </div>
      </TooltipTrigger>
      <TooltipContent>{tooltip}</TooltipContent>
    </Tooltip>
  );
};

export default TextButton;
