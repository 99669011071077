import { Getter, PaginationState } from '@tanstack/react-table';
import dayjs from 'dayjs';
import { useEffect, useMemo, useState } from 'react';
import {
  DataTableColumnDef,
  DataTableWithActions,
  createCallObjectiveColumn,
  createProspectColumn,
  createProspectTypeColumn,
} from '../../components';
import { PAGINATION_PAGE_SIZE } from '../../constants';
import { useAppSelector, useGetFiltersFromParams } from '../../hooks';
import { useGetCallsMutation } from '../../services';
import { CallHistoryFilters, CallHistoryRow, DateFormat, OrgRoles } from '../../types';
import { conditionalArray, formatDuration, getCallerInfo, getStartAndEndDatesFromDateOption } from '../../utils';
import useCallActions from './useCallActions';

/**
 * Gets the caller filter based on the caller value.
 * If the caller is a phone number, filter by incoming phone number.
 * If the caller is a user ID, filter by user ID.
 */
const parseCallerFilter = (caller?: string) => {
  if (!caller || !caller.length) return;

  const isPhoneNumber = caller[0] === '+';
  if (isPhoneNumber) {
    return { incomingPhoneNumber: caller };
  } else {
    return { userId: caller };
  }
};

const CallHistoryPage = () => {
  // State to track the clicked row.
  const [clickedRowIndex, setClickedRowIndex] = useState<number | undefined>(undefined);
  // State for pagination settings.
  const [pagination, setPagination] = useState<PaginationState>({
    pageIndex: 1,
    pageSize: PAGINATION_PAGE_SIZE,
  });

  // Redux
  const user = useAppSelector((state) => state.auth.user);

  // Mutations
  // Fetch calls with loading state management.
  const [getCalls, { data, isLoading }] = useGetCallsMutation();
  const calls = data?.calls || [];
  const totalPages = data?.pagination.totalPages || 0;

  // Custom hooks
  const filters: CallHistoryFilters = useGetFiltersFromParams();

  const clickedCall = useMemo(() => {
    if (clickedRowIndex === undefined) return;
    return calls[clickedRowIndex];
  }, [calls, clickedRowIndex]);

  const actions = useCallActions(() => setClickedRowIndex(undefined), clickedCall);

  // Fetch calls whenever filters or pagination settings change.
  useEffect(() => {
    getCalls({
      personaId: filters.prospect,
      startTime: getStartAndEndDatesFromDateOption(filters.callDate),
      prospectType: filters.prospectType,
      objective: filters.callObjective,
      pagination,
      ...parseCallerFilter(filters.caller),
    });
  }, [filters, pagination]);

  if (!user) return null;

  const isSalesRep = user.role === OrgRoles.SALES_REP;

  // Transform the fetched calls data to match the table's expected row structure.
  const parsedCalls: CallHistoryRow[] = calls.map((call) => ({
    callDate: call.startTime,
    callObjective: call.practiceProspect.objective,
    prospectType: call.practiceProspect.type,
    duration: call.callDuration || 0,
    prospect: {
      name: `${call.practiceProspect.firstName} ${call.practiceProspect.lastName}`,
      jobTitle: call.practiceProspect.jobTitle,
      company: call.practiceProspect.accountName,
    },
    caller: getCallerInfo(call, user).name,
  }));

  // Define columns for the data table.
  const columns: DataTableColumnDef<CallHistoryRow>[] = [
    // Conditionally include the "Caller" column if the user is not a sales representative.
    // This is because a sales rep can only see their own call history.
    ...conditionalArray(!isSalesRep, {
      accessorKey: 'caller',
      header: 'Caller',
      cell: ({ getValue }: { getValue: Getter<string | undefined> }) => {
        const caller = getValue();
        return caller ?? 'N/A';
      },
    } as DataTableColumnDef<CallHistoryRow>),
    {
      accessorKey: 'callDate',
      header: 'Call Date',
      cell: ({ getValue }: { getValue: Getter<Date> }) => {
        const date = new Date(getValue());
        return dayjs(date).format(DateFormat.FULL_DATE);
      },
    },
    createProspectColumn(),
    createProspectTypeColumn(),
    createCallObjectiveColumn(),
    {
      accessorKey: 'duration',
      header: 'Duration',
      cell: ({ getValue }: { getValue: Getter<number> }) => {
        const duration = getValue();
        return formatDuration(duration);
      },
    },
  ];

  return (
    <DataTableWithActions
      actions={actions}
      columns={columns}
      isLoading={isLoading}
      data={parsedCalls}
      clickedRowIndex={clickedRowIndex}
      setClickedRowIndex={setClickedRowIndex}
      paginationControls={{ pagination, totalPages, setPagination }}
    />
  );
};

export default CallHistoryPage;
