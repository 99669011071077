import { createContext } from 'react';
import { AlertType } from '../../components';

export interface ToastProps {
  message: string;
  type?: AlertType;
}

interface ToastContextType {
  showToast: ({ message, type }: ToastProps) => void;
}

const ToastContext = createContext<ToastContextType | undefined>(undefined);

export default ToastContext;
